import React, { useEffect, useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
// import { media } from 'styled-bootstrap-grid'
import { Section } from 'components/Layout'


const VideoWrapper = styled.div`
  width: calc(80%);
  margin: 0 auto;
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.shadows.main};
  overflow: hidden;
`;

const StyledVideo = styled.video`
  width: 100%;
  margin-left: -1px;
  display: block;
  border-radius: ${({ theme }) => theme.borders.radius};
  height: 100%;
`;

const FeaturesVideSlice = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
  
      if (entry.isIntersecting && videoRef.current && videoRef.current.paused) {
        videoRef.current.play();
      } else if (!entry.isIntersecting && videoRef.current) {
        videoRef.current.pause();
      }
    }, {
      threshold: 0.5 
    });
  
    if (videoRef.current) {
      observer.observe(videoRef.current);
  
      videoRef.current.play().catch(() => {
        // Autoplay might be blocked by the browser
      }); 
    }
  
    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  const data = useStaticQuery(graphql`
    query FeaturesTeaserVideoQuery {
      templateEditorVideo: file(relativePath: { eq: "template-editor.mp4" }) {
        publicURL
      }
    }
  `)

  return (
    <Section>
      <VideoWrapper>
        <StyledVideo ref={videoRef} autoPlay muted loop>
          <source src={data.templateEditorVideo.publicURL} type="video/mp4" />
        </StyledVideo>
      </VideoWrapper>
    </Section>
  )
}

export default FeaturesVideSlice
