/* eslint-disable import/no-unresolved */
import * as React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { LinkButton } from 'components/Shared'
import Logo from 'images/logo-ab-testing.svg'
import DropDownArrow from 'images/arrow-drop-down.svg'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { useState, useEffect } from 'react'
import HeaderBurger from './MobileBurger'
import { getOutlink } from '../../utils/analyticsUtils'
import useWindowSize from '../../hooks/useGatsbyWindowSize'

const StyledDropDown = styled(DropDownArrow)`
  margin-bottom: 4px;
  margin-left: 4px;
`

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 500;
  background-color: ${({ theme, isLight }) =>
    isLight ? theme.palette.background.main : theme.palette.primary.main};
  transition: box-shadow 0.25s ease-in;
  box-shadow: ${({ active, theme }) => (active ? theme.shadows.light : 'none')};
`

const HeaderContent = styled.div`
  margin: 0 auto;
  max-width: 1350px;
  height: ${({ theme }) => theme.sizes.headerHeight};
  padding: 1.25rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const LeftContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const HeaderLinks = styled.div`
  display: none;
  ${media.lg`
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  `}
`

const StyledLogo = styled(Logo)`
  margin-top: ${({ theme }) => theme.sizes.headerLogoTopMargin};
  width: ${({ theme }) => theme.sizes.headerLogoWidth};
`

const LogoLink = styled(Link)`
  position: relative;
  text-decoration: none;
  padding: 0;
  ${media.lg`
    padding-right: 20px;
  `}
  ${media.xl`
    padding-right: 48px;
  `}
`

const HeaderLink = styled(Link)`
  white-space: nowrap;
  position: relative;
  text-decoration: none;
  padding: 0px 12px;
  border-radius: 4px;
  color: ${({ theme, lightbackground }) =>
    lightbackground
      ? theme.palette.text.primary
      : theme.palette.text.secondary};
  transition: all 0.25s ease-in-out;
  &:hover {
    font-weight: 500 !important;
    color: ${({ theme, lightbackground }) =>
    lightbackground
      ? theme.palette.primary.main
      : theme.palette.secondary.dark};
    background-color: #FFFFFF10;
  }
  &.active {
    color: ${({ theme, lightbackground }) =>
    lightbackground
      ? theme.palette.primary.main
      : theme.palette.secondary.dark};
    text-decoration: underline 2.5px;
    text-underline-offset: ${({ ismobile }) => (ismobile ? '8px' : '12px')};
  }
  ${media.xxl`
    padding: 0px 24px;
  `}
`

const StyledHeaderElement = styled.p`
  padding: 0;
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-size: ${({ theme }) => theme.fontSizes.body2};
  font-weight: 500;
  line-height: 20px;
  margin: 12px 0;
`

const RightContent = styled.div`
  display: none;
  ${media.lg`
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  `}
`

const RightContentMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  ${media.lg`
    display: none;
  `}
`

const MobileMenu = styled.div`
  display: block;
  overflow: hidden;
  z-index: 100;
  padding: 64px 32px;
  position: absolute;
  width: calc(100% - 64px);
  height: 100vh;
  background-color: ${({ theme, lightbackground }) =>
    lightbackground
      ? theme.palette.text.secondary
      : theme.palette.primary.main};
  color: ${({ theme, lightbackground }) =>
    lightbackground
      ? theme.palette.text.primary
      : theme.palette.text.secondary};
  right: -100%;
  transition: all ease-in 0.25s;
  ${({ active }) => active && 'right: 0%;'}
  ${media.lg`
    display: none;
  `}
`

const OutLink = styled.a`
  position: relative;
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.25s ease-in-out;
  padding: 0px 12px;
  color: ${({ theme, lightbackground }) =>
    lightbackground
      ? theme.palette.text.primary
      : theme.palette.text.secondary};
  &:hover {
    font-weight: 500 !important;
    color: ${({ theme, lightbackground }) =>
    lightbackground
      ? theme.palette.primary.main
      : theme.palette.secondary.dark};
    background-color: #FFFFFF10;
  }
`

const LoginLink = styled(OutLink)`
  margin-right: 12px;
`

const DropDown = styled.div`
  background-color: white;
  opacity: 0;
  visibility: hidden;
  width: 134px;
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadows.extralight};
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  
  ${media.lg`
    display: block;
    opacity: ${({ open }) => (open ? 1 : 0)};
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  `}
`


const DropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 124px;
  ${media.xxl`
    margin-left 20px;
  `}
`

const DropDownButton = styled.p`
  margin-top: -6px;
  margin-bottom: 0px;
  padding: 8px 12px;
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-size: ${({ theme }) => theme.fontSizes.body2};
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
  color: ${({ theme, lightbackground }) =>
    lightbackground
      ? theme.palette.text.primary
      : theme.palette.text.secondary};
  transition: all 0.25s ease-in-out;
  &:hover {
    color: ${({ theme, lightbackground }) =>
    lightbackground
      ? theme.palette.primary.light
      : theme.palette.secondary.dark};
    background-color: #FFFFFF10;
  }
`

const DropDownLink = styled(Link)`
  margin: 0px;
  text-decoration: none;
  height: fit-content;
`

const DropDownElement = styled(StyledHeaderElement)`
  margin: 24px 12px;
  color:  ${({ theme }) => theme.palette.primary.main};
`

const headerLinks = [
  { to: '/features', label: 'Features' },
  { to: '/pricing', label: 'Pricing' },
  { to: '/case-studies', label: 'Success Stories' },
  { label: 'Resources' },
  { to: '/editor/?template=Semplates%20Demo%20Template', label: 'Try the editor' },
]

const dropDownLinks = [
  { to: '/blog', label: 'Blog' },
  { to: '/documentation', label: 'Documentation' },
]

const outLinks = {
  login: {
    to: 'https://app.semplates.io/login',
    label: 'Login',
  },
  register: {
    to: getOutlink(),
    label: 'Signup for free',
    labelShort: 'Signup',
  },
}

const Header = ({ lightbackground }) => {
  const [didScroll, setDidScroll] = useState(false)
  const [dropDownOpen, setDropDownOpen] = useState(false)
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  useScrollPosition(
    ({ currPos }) => setDidScroll(currPos.y < 0),
    [setDidScroll],
    false,
    false,
    150
  )
  const { width } = useWindowSize()
  const handleClickAnywhere = () => {
    if(dropDownOpen) {setDropDownOpen(false)}
  }

  useEffect(() => {
    document.addEventListener('click', handleClickAnywhere);
    return () => {
      document.removeEventListener('click', handleClickAnywhere);
    };
  }, [dropDownOpen]);

  return (
    <div>
      <StyledHeader active={didScroll} isLight={lightbackground ? 1 : 0}>
        <HeaderContent>
          <LeftContent>
            <LogoLink to="/" aria-label="Go to Semplates.io' main page">
              <StyledLogo alt="Semplates logo"/>
            </LogoLink>
            <HeaderLinks>
              {headerLinks.map((link, index) => {
                if (link.label === 'Resources')
                  return (
                    <DropDownContainer key={index}>
                      <DropDownButton
                        lightbackground={lightbackground ? 1 : 0}
                        onClick={() => setDropDownOpen(!dropDownOpen)}
                      >
                        {link.label}
                        <StyledDropDown />
                      </DropDownButton>
                      <DropDown
                        lightbackground={lightbackground ? 1 : 0}
                        open={dropDownOpen ? 1 : 0}
                      >
                        {dropDownLinks.map((dropdownLink, dropdownIndex) => (
                          <DropDownLink
                            to={dropdownLink.to}
                            key={`dropdown-link-${dropdownIndex}`}
                            lightbackground={lightbackground ? 1 : 0}
                            activeClassName="active"
                          >
                            <DropDownElement>
                              {dropdownLink.label}
                            </DropDownElement>
                          </DropDownLink>
                        ))}
                      </DropDown>
                    </DropDownContainer>
                  )
                return (
                  <HeaderLink
                    to={link.to}
                    key={index}
                    lightbackground={lightbackground ? 1 : 0}
                    activeClassName="active"
                  >
                    <StyledHeaderElement>{link.label}</StyledHeaderElement>
                  </HeaderLink>
                )
              })}
            </HeaderLinks>
          </LeftContent>
          <RightContent>
            <LoginLink
              href={outLinks.login.to}
              target="_blank"
              rel="noopener noreferrer"
              lightbackground={lightbackground ? 1 : 0}
            >
              <StyledHeaderElement>{outLinks.login.label}</StyledHeaderElement>
            </LoginLink>
            <LinkButton
              size="sm"
              to={outLinks.register.to}
              label={
                width > 1150
                  ? outLinks.register.label
                  : outLinks.register.labelShort
              }
            />
          </RightContent>
          <RightContentMobile>
            <HeaderBurger
              open={showMobileMenu}
              onClick={() => setShowMobileMenu(!showMobileMenu)}
              inverted={lightbackground ? 0 : 1}
            />
          </RightContentMobile>
        </HeaderContent>

        <MobileMenu
          active={showMobileMenu}
          lightbackground={lightbackground ? 1 : 0}
        >
          {[...headerLinks, ...dropDownLinks].map((link, index) => {
            if (link.label === 'Resources') return <></>
            return (
              <HeaderLink
                to={link.to}
                key={`resource-link-${index}`}
                lightbackground={lightbackground ? 1 : 0}
                activeClassName="active"
                ismobile={1}
              >
                <StyledHeaderElement>{link.label}</StyledHeaderElement>
              </HeaderLink>
            )
          })}
          <LoginLink
            href={outLinks.login.to}
            target="_blank"
            rel="noopener noreferrer"
            lightbackground={lightbackground ? 1 : 0}
          >
            <StyledHeaderElement>{outLinks.login.label}</StyledHeaderElement>
          </LoginLink>
          <LinkButton
            size="sm"
            to={outLinks.register.to}
            label={outLinks.register.label}
          />
        </MobileMenu>
      </StyledHeader>
    </div>
  )
}

export default Header
