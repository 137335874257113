import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const IconLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-family: Exo;
  font-size: ${({ theme }) => theme.fontSizes.body2};
  font-weight: 300;
  letter-spacing: 0em;
  display: inline-flex;
  text-align: left;
  align-items: center;
`

const ImageContainer = styled.span`
  width: 20px;
  height: 20px;
`

const SocialLinks = () => {
  const data = useStaticQuery(graphql`
    query SocialLinkQuery {
      allContentfulSocialLink(sort: { fields: name, order: ASC }) {
        nodes {
          name
          url
          logo {
            gatsbyImageData(placeholder: NONE)
            description
          }
        }
      }
    }
  `)

  const sliceContent = data.allContentfulSocialLink.nodes
  return (
    <>
      {sliceContent.map((node, index) => (
        <React.Fragment key={index}>
          <IconLink href={node.url} target="_blank">
            <ImageContainer>
              <GatsbyImage
                image={getImage(node.logo)}
                alt={node.logo.description}
              />
            </ImageContainer>
            &nbsp;
          </IconLink>
        </React.Fragment>
      ))}
    </>
  )
}

export default SocialLinks
