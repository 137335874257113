import * as axios from 'axios'

const trackEvent = async eventName => {
  await axios({
    method: 'PUT',
    url: `${process.env.GATSBY_API_ENDPOINT}/analytics/event`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      event: eventName,
    },
  })
}

export default trackEvent
