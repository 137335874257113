import React from 'react'
import { Col, Row, Section } from 'components/Layout'
import { FadeOnSlide, LinkButton } from 'components/Shared'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const StyledRow = styled(Row)`
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  ${media.lg`
    flex-direction: row;
    justify-content: space-between;
  `}
`

const TextCol = styled(Col)`
  justify-content: flex-start;
  margin: 24px 0;

  ${media.lg`
    margin: 32px
  `}
`

const ImageCol = styled(TextCol)`
  align-items: center;
`

const StyledImage = styled(GatsbyImage)`
  position: relative;
  border-radius: 8px;
  margin: 0 10%;
  margin-top: 8px;
  width: 100%;
  ${media.lg`
    right: 24px;
  `}
`

const ButtonCaption = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

const FeaturesTeaserSlice = () => {
  const sliceContent = {
    title: 'Our features have you covered',
    text: 'Create, edit and send email templates on AWS without a single line of code',
    buttonCaption: 'Find out how:',
    button: { label: 'See feature description', to: '/features' },
    imageAlt:
      'Create, edit and send email templates on AWS without a single line of code',
  }

  const data = useStaticQuery(graphql`
    query featuresTeaserQuery {
      teaser: file(relativePath: { eq: "hero.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 65
            width: 900
            placeholder: NONE
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)
  const image = getImage(data.teaser)

  return (
    <Section>
      <StyledRow>
        <ImageCol>
          <StyledImage image={image} alt={sliceContent.imageAlt} />
        </ImageCol>
        <FadeOnSlide>
          <TextCol>
            <h2>{sliceContent.title}</h2>
            <p>{sliceContent.text}</p>
            <ButtonCaption>{sliceContent.buttonCaption}</ButtonCaption>
            <LinkButton
              to={sliceContent.button.to}
              label={sliceContent.button.label}
            />
          </TextCol>
        </FadeOnSlide>
      </StyledRow>
    </Section>
  )
}

export default FeaturesTeaserSlice
