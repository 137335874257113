import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Lightbox from 'react-image-lightbox';
import { DocumentationSearch } from '../Documentation'
import { ArticleContent, H2, MarginBottom, NavigationHeader, SmallText, StyledFlag } from '../../utils/templates/styles';
import { TableOfContents } from '../Shared/ContentTable';
import useWindowSize from '../../hooks/useGatsbyWindowSize';
import 'react-image-lightbox/style.css';


const StyledSubtitle = styled.p`
  & a {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    text-decoration: none;
  }
`
const HideOnMobile = styled.div`
  display: none;
  ${media.xl`
    display: initial;
  `}
`

const H1 = styled.h1`
  color: ${({ theme }) => theme.palette.primary.main};
`

const Section = styled.div`
  ${media.md`
    margin-left: 24px;
  `}
`

const ArticleContainer = styled.div`
  max-width: 660px;
`

const ArticleNavigation = styled.div`
  width: 210px;
  margin-left: 32px;
  position: sticky;
  top: 80px;
`

const Container = styled.div`
  display: flex;
  height: 100%;
  margin-top: 32px;
`

const BarContainer = styled.div`
  padding-top: 24px;
  margin-top: -24px;
  border-radius: 0 0 8px 8px;
  transition: width 0.25s ease; 
  position: sticky;
  background-color: ${({ theme }) => theme.palette.background.gray};
  top: 68px;
  z-index: 200;
  margin-left: -2px;
  padding-right: 4px;
  display: none;
  ${media.md`
    display: block;
  `}
`

const CategoryContainer = styled(Link)`
  background-color: white;
  border: solid 1px;
  border-color: ${({ theme }) => theme.palette.border.gray};
  color: ${({ theme }) => theme.palette.primary.gray};
  border-radius: 12px;
  padding: 4px 24px 8px 24px;
  text-decoration: none;
  & p {
    font-family: ${({ theme }) => theme.fontFamilies.secondary};
    font-size: 14px;
    line-height: 22px;
  }
`

const CategoryTitle = styled.p`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.primary.main};
`

const Flex = styled.div`
  gap: 24px;
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.palette.border.gray};
  padding-bottom 24px;
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  ${media.sm`
    flex-direction: row;
  `}
`

const HideOverflow = styled.div`
  contain: paint;
  width: 100%;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSizes.body2} !important;
  color: ${({ theme }) => theme.palette.primary.gray} !important;
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-weight: 500 !important;
  &:hover {
    color: ${({ theme }) => theme.palette.primary.main} !important;
  }
`

const StyledSmallText = styled(SmallText)`
  color: ${({ theme }) => theme.palette.primary.main} !important;
`

const Hr = styled.hr`
  margin-top: -48px;
`

const PrevNext = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`

const PrevNextContainer = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  gap: 20px;
  color: ${({ theme }) => theme.palette.primary.main};
  max-width: 200px;
  ${media.md`
    width: 200px;
  `}
`

const PrevNextHeader = styled.p`
  min-height: 60px;
  display: block;
  font-size: ${({ theme }) => theme.fontSizes.body2};
  font-weight: ${({ theme }) => theme.fontWeights.bold} !important;
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  line-height: 20px;
  padding: 0 !important;
  margin: 0;
  ${media.md`
    width: 160px;
  `}
`
const PrevNextTitle = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.body2};
  color: ${({ theme }) => theme.palette.primary.gray};
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-weight: 500;
  padding-bottom: 0 !important;
  margin-bottom: 12px !important;
`

const Prev = styled.div`
`

const Next = styled.div`
  margin-left: auto;
  text-align: end;
  & p {
    text-align: end;
  }

`

const Stretch = styled.span`
  display: inline-block;
  transform: scaleY(1.5);
`

const DocumentationArticleSlice = ({ content, title, subTitle, setupSlug, templateDesignSlug, faqSlug, category, categorySlug, next, prev }) => {
  const sliceContent = {
    title: 'Documentation',
    paragraph:
      'Welcome to our documentation!This is the right place to find everything you need to know when you begin using Semplates. Connect & configure AWS, get your colleagues on board and start using SES like a pro.',
    subText: 'Can´t find the information you were looking for? Shoot us',
    outLink:
      'mailto:info@semplates.io?subject=Question%20regarding%20Semplates',
    linkText: 'an email.',
    getStartedTitle: 'Getting Started',
    getStartedParagraph: 'Everything you need to know when you begin using Semplates. Connect & configure AWS, get your colleagues on board and start using SES like a pro.',
    templateDesignTitle: 'Template Design',
    templateDesignParagraph: 'Create better email templates for AWS SES with Semplates. Learn how our advanced feature set can help you creating emails that work',
    faqTitle: 'FAQ',
    faqParagraph: 'Here you can find answers to the most common questions regarding Semplates and AWS SES. If you can´t find the answer to your question, feel free to contact us.',
  }
  const [isSticky, setIsSticky] = useState(false)
  const { width } = useWindowSize();

  const [isLightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [imageSrcList, setImageSrcList] = useState([]);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 40)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (content?.html) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(content.html, 'text/html');
      const images = doc.querySelectorAll('img');
      const imageSources = Array.from(images).map(img => img.src);
      setImageSrcList(imageSources);
    }
  }, [content?.html]);

  return (
    <HideOverflow id="closeSearchBar">
      <Section>
        <BarContainer style={{ width: (isSticky && width > 1199) ? 'calc(100% - 240px)' : '100%' }}>
          <DocumentationSearch />
        </BarContainer>
        <Container>
          {content ? (
            <ArticleContent>
              <StyledSmallText>
                <StyledLink to={[setupSlug, templateDesignSlug, faqSlug].find(item => item?.includes(categorySlug))}>
                  {category} &nbsp;&gt;&nbsp;
                </StyledLink>
                {title}
              </StyledSmallText>
              <H1>{title}</H1>
              {subTitle && <StyledSubtitle>{subTitle}</StyledSubtitle>}
              <ArticleContent 
                dangerouslySetInnerHTML={{ __html: content.html }}
                onClick={(e) => {
                  if (e.target.tagName === 'IMG') {
                    const index = imageSrcList.indexOf(e.target.src);
                    if (index !== -1) {
                      setPhotoIndex(index);
                      setLightboxOpen(true);
                    }
                  }
                  if (e.target.tagName === 'A') {
                    const { href } = e.target;

                    if (href.includes('semplates.io')) {
                      e.preventDefault();
                      window.location.href = href;
                    }
                  }
                }}
              />
              <H2>Any question?</H2>
              <MarginBottom>
                <p>Feel free to <a href="mailto:support@semplates.io">contact us</a> anytime if you have any questions.</p>
              </MarginBottom>
              <Hr />
              <PrevNext>
                {prev ? (
                  <PrevNextContainer to={`/documentation/${prev.documentationCategory.slug}/${prev.slug}`}>
                    <Stretch>{"<"}</Stretch>
                    <Prev>
                      <PrevNextTitle>Previous</PrevNextTitle>
                      <PrevNextHeader>{prev.title}</PrevNextHeader>
                    </Prev>
                  </PrevNextContainer>) : <PrevNextContainer />
                }
                {next ? (
                  <PrevNextContainer to={`/documentation/${next.documentationCategory.slug}/${next.slug}`}>
                    <Next>
                      <PrevNextTitle>Next</PrevNextTitle>
                      <PrevNextHeader>{next.title}</PrevNextHeader>
                    </Next>
                    <Stretch>{">"}</Stretch>
                  </PrevNextContainer>) : <PrevNextContainer />}
              </PrevNext>
            </ArticleContent>) :
            (<ArticleContainer>
              <ArticleContent>
                <H1>{sliceContent.title}</H1>
                <p>{sliceContent.paragraph}</p>
                <StyledSubtitle>
                  <span>{sliceContent.subText} </span>
                  <span>
                    <a href={sliceContent.outLink}> {sliceContent.linkText}</a>
                  </span>
                </StyledSubtitle>
              </ArticleContent>
              <Flex>
                <CategoryContainer to={setupSlug}>
                  <CategoryTitle>{sliceContent.getStartedTitle}</CategoryTitle>
                  <p>{sliceContent.getStartedParagraph}</p>
                </CategoryContainer>
                <CategoryContainer to={templateDesignSlug}>
                  <CategoryTitle>{sliceContent.templateDesignTitle}</CategoryTitle>
                  <p>{sliceContent.templateDesignParagraph}</p>
                </CategoryContainer>
                <CategoryContainer to={faqSlug}>
                  <CategoryTitle>{sliceContent.faqTitle}</CategoryTitle>
                  <p>{sliceContent.faqParagraph}</p>
                </CategoryContainer>
              </Flex>
              <PrevNext>
                <PrevNextContainer />
                <PrevNextContainer to={`${next.documentationCategory.slug}/${next.slug}`}>
                  <Next>
                    <PrevNextTitle>Next</PrevNextTitle>
                    <PrevNextHeader>{next.title}</PrevNextHeader>
                  </Next>
                  <Stretch>{">"}</Stretch>
                </PrevNextContainer>
              </PrevNext>
            </ArticleContainer>)
          }
          <HideOnMobile>
            <ArticleNavigation>
              {content ? (
                <>
                  <NavigationHeader>
                    <StyledFlag />
                    <p>On this page</p>
                  </NavigationHeader>
                  <TableOfContents headings={content.headings} offset={160} />
                </>)
                : (<></>)
              }
            </ArticleNavigation>
          </HideOnMobile>
        </Container>
        {isLightboxOpen && (
          <Lightbox
            mainSrc={imageSrcList[photoIndex]}
            nextSrc={imageSrcList[(photoIndex + 1) % imageSrcList.length]}
            prevSrc={imageSrcList[(photoIndex + imageSrcList.length - 1) % imageSrcList.length]}
            onCloseRequest={() => setLightboxOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + imageSrcList.length - 1) % imageSrcList.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % imageSrcList.length)
            }
          />
        )}
      </Section>
    </HideOverflow>
  )
}

export default DocumentationArticleSlice
