import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Section, Row } from 'components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import { SliderDot, StyledSlider } from 'components/Shared'
import useWindowSize from '../../hooks/useGatsbyWindowSize'

const PaddedRow = styled(Row)`
  padding: 64px 0 32px 0;
`

const SliderContainer = styled.div`
  width: 100vw;
  min-height: 520px;
  overflow: hidden;
`

const SlideContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Dots = styled.div`
  margin-top: 60px;
`

const TemplateStartersSlice = () => {
  const theme = useTheme()
  const sliceContent = {
    sliceTitle: 'Email templates ready for your branding',
    alt: 'Semplates email template starters are a great way to get started developing hyper-personalized email templates for AWS SES quickly.',
    imageStyles: {
      borderRadius: '15px',
      boxShadow: theme.shadows.main,
      margin: '0 28px',
    },
    imagePaths: [
      '../../images/starter-1.png',
      '../../images/starter-2.png',
      '../../images/starter-3.png',
      '../../images/starter-4.png',
    ],
  }

  const ImageCarousel = () => {
    const { width } = useWindowSize()
    const sliderSettings = {
      customPaging: () => (
        <Dots>
          <SliderDot />
        </Dots>
      ),
      dots: true,
      dotsClass: 'slick-dots',
      infinite: true,
      autoplaySpeed: 4000,
      speed: 1000,
      autoplay: true,
      slidesToShow: width < theme.breakpoints.lg ? 1 : 4,
      slidesToScroll: 1,
    }
    const marginTop =
      width < theme.breakpoints.lg ? { margin: 'auto' } : { marginTop: '64px' }
    const marginBottom =
      width < theme.breakpoints.lg
        ? { margin: 'auto' }
        : { marginBottom: '64px' }

    return (
      <SliderContainer>
        <StyledSlider {...sliderSettings}>
          <SlideContainer>
            <StaticImage
              src="../../images/starter-1.png"
              alt={sliceContent.alt}
              placeholder="none"
              layout="fixed"
              width={240}
              height={440}
              style={{ ...sliceContent.imageStyles, ...marginTop }}
            />
          </SlideContainer>
          <SlideContainer>
            <StaticImage
              src="../../images/starter-2.png"
              alt={sliceContent.alt}
              placeholder="none"
              layout="fixed"
              width={240}
              height={440}
              style={{ ...sliceContent.imageStyles, ...marginBottom }}
            />
          </SlideContainer>
          <SlideContainer>
            <StaticImage
              src="../../images/starter-3.png"
              alt={sliceContent.alt}
              placeholder="none"
              layout="fixed"
              width={240}
              height={440}
              style={{ ...sliceContent.imageStyles, ...marginTop }}
            />
          </SlideContainer>
          <SlideContainer>
            <StaticImage
              src="../../images/starter-4.png"
              alt={sliceContent.alt}
              placeholder="none"
              layout="fixed"
              width={240}
              height={440}
              style={{ ...sliceContent.imageStyles, ...marginBottom }}
            />
          </SlideContainer>
        </StyledSlider>
      </SliderContainer>
    )
  }

  return (
    <Section bgStyle="light">
      <Row alignItems="center" justifyContent="center">
        <h2>{sliceContent.sliceTitle}</h2>
      </Row>
      <PaddedRow>
        <ImageCarousel />
      </PaddedRow>
    </Section>
  )
}

export default TemplateStartersSlice
