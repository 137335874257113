import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Arrow from 'images/arrow-forward.svg'
import { media } from 'styled-bootstrap-grid'

const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 400px;
  border-radius: ${({ theme }) => theme.borders.radius};
  background-color: ${({ theme }) => theme.palette.background.main};
  color: ${({ theme }) => theme.palette.text.primary};
  transition: all 1s ease;
  text-decoration: none !important;
  overflow: hidden;
  width: 300px;
  ${media.xs`
    width: 280px;
  `}
  &:hover {
    box-shadow: ${({ theme }) => theme.shadows.main};
  }
`

const CardContent = styled.div`
  padding: 16px 16px 12px 16px;
  flex-direction: column;
  flex-grow: 1;
  background-color: white;
  color: ${({ theme }) => theme.palette.primary.gray};
  line-height: 22px;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.palette.primary.dark};
  font-size: 24px;
  margin-bottom: 12px;
`

const ImageContainer = styled.div`
  border-top-left-radius: ${({ theme }) => theme.borders.radius};
  border-top-right-radius: ${({ theme }) => theme.borders.radius};
  display: grid;
  place-items: center;
  padding: 32px;
  padding-left: auto;
  padding-right: auto;
  height: 200px;
`

const StyledMore = styled(Link)`
  display: flex;
  align-items: center;
  gap: 16px;
  display: flex;
  justify-content: center;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 14px;
  line-height: 3.2;
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: none;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: calc(100% - 200px);
    width: 0;
    height: 2px;
    background-color: ${({ theme }) => theme.palette.primary.main};
    transition: width 0.3s ease-out;
  }
  &:hover::after {
    width: 200px; 
  }
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`

const CaseStudyCard = ({ post, active, slideItem }) => {
  const image = getImage(post?.heroImage)
  const description =
    post?.shortDescription > 160
      ? `${post.shortDescription.substring(0, 200)}...`
      : post?.shortDescription
  const { title, slug } = post
  return (
    <Link style={{ textDecoration: "none" }} to={`/case-studies/${slug}`}>
      <CardContainer active={active} slideItem={slideItem}>
        <ImageContainer active={active} slideItem={slideItem}>
          <GatsbyImage
            image={image}
            alt={post?.heroImage?.description ?? title}
          />
        </ImageContainer>
        <CardContent>
          <Title>{title}</Title>
          {description}
          <FlexContainer>
            <StyledMore to={`/case-studies/${slug}`} >Read more on {title} <Arrow /></StyledMore>
          </FlexContainer>
        </CardContent>
      </CardContainer>
    </Link>
  )
}

export default CaseStudyCard
