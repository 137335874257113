import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { CaretRight } from '../../images/documentation'
import { Col, Row } from '../Layout'

export const CardContainer = styled.div`
  border-radius: ${({ theme }) => theme.borders.radius};
  background-color: ${({ theme }) => theme.palette.background.main};
  color: ${({ theme }) => theme.palette.text.primary};
  box-shadow: ${({ theme }) => theme.shadows.extralight};
  padding: 24px;
  margin: 24px 0;
  max-width: 100%;
  min-height: 400px;
`

const TitleRow = styled(Row)`
  align-items: flex-start;
  justify-content: flex-start;
  ${media.lg`
    justify-content: space-between;
  `}
`

const ContentRow = styled(Row)`
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  ${media.lg`
    flex-direction: row;
    justify-content: space-between;
  `}
`

const DescriptionCol = styled(Col)`
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;

  ${media.lg`
    padding-right 30px;
    width: 40%;
  `}

  & a {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    text-decoration: none;
  }
`

const ItemsCol = styled(Col)`
  display: flex;
  flex-direction: column;

  ${media.lg`
    padding-left: 130px;
    padding-right: 130px;
    width: 50%;
  `}
`

const ArticleTitle = styled.p`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  letter-spacing: 0.05em;
  margin: 16px 0;
`

const ArticleLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.text.primary};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 8px;aber 
`

const StyledCaret = styled(CaretRight)`
  margin-right: 24px;
`

const Article = ({ article: { slug, title } }) => (
  <ArticleLink to={slug}>
    <StyledCaret />
    <ArticleTitle>{title}</ArticleTitle>
  </ArticleLink>
)

const DocumentationDetailCard = ({
  category: {
    title,
    childrenContentfulDocumentationCategoryCategoryTextTextNode,
    slug,
  },
}) => {
  const description =
    childrenContentfulDocumentationCategoryCategoryTextTextNode[0]
      .childMarkdownRemark.html
  const data = useStaticQuery(graphql`
    query documentationPagesPerCategoryWithTitle {
      allContentfulDocumentationPage(sort: { fields: sortOrder, order: ASC }) {
        nodes {
          slug
          id
          documentationCategory {
            slug
          }
          sortOrder
          subTitle
          title
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)

  const articlesFilteredForCategory =
    data.allContentfulDocumentationPage.nodes.filter(
      node => node.documentationCategory.slug === slug
    )

  return (
    <CardContainer>
      <TitleRow>
        <DescriptionCol>
          <h2>{title}</h2>
        </DescriptionCol>
      </TitleRow>
      <ContentRow>
        <DescriptionCol dangerouslySetInnerHTML={{ __html: description }} />
        {articlesFilteredForCategory.length > 0 && (
          <ItemsCol>
            {articlesFilteredForCategory.map((article, idx) => (
              <Article article={article} key={idx} />
            ))}
          </ItemsCol>
        )}
      </ContentRow>
    </CardContainer>
  )
}

export default DocumentationDetailCard
