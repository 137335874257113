import React from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Arrow from 'images/arrow-forward.svg'
import { Section } from '../Layout'
import Tag from '../Blog/Tag'
import StyledTag from '../Shared/StyledTag'

const PostContainer = styled.div`
  width: 100%;
  min-height: 500px;
  background-color: white;
  border-radius: 16px;
  position: relative;
  margin-top: -300px;
  top: 300px;
  display: flex;
  flex-direction: column-reverse;
  z-index: 10;
  ${media.sm`
    flex-direction: row;
  `}
`

const PostImage = styled.div`
  display: grid;
  place-items: center;
  ${media.lg`
    width: 50%;
    padding: 36px;
  `}
`

const ImageContainer = styled.div`
  height: 100%;
  display: grid;
  align-items: center;
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  ${media.sm`
    border-radius: 16px;
    overflow: visible;
  `}
`

const PostContent = styled.div`
  height: fit-content;
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 16px;
  align-self: center;
  ${media.lg`
    padding: 36px;
    width: 50%;
  `}
`

const TitleTag = styled(StyledTag)`
  margin-bottom: 20px;
  margin-top: 16px;
`
const H2 = styled.h2`
  font-family: ${({ theme }) => theme.fontFamilies.main};
  font-weight: ${({ theme }) => theme.fontWeights.titleBold};
  max-width: 520px;
`

const SubTitle = styled.p`
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
`

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

const Margin = styled.div`
  padding-bottom: 300px;
  background-color: ${({ theme }) => theme.palette.background.gray} !important;
`

const StyledImage = styled(GatsbyImage)`
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
`

const StyledMore = styled(Link)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 14px;
  line-height: 3.2;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
`

const BlogHeaderSlice = () => {
  const data = useStaticQuery(graphql`
    query FeaturedPost {
      allContentfulFeaturedArticle {
        nodes {
          featuredPost {
            topicTags
            heroImage {
              gatsbyImageData(
                width: 800
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
              )
              description
            }
            title
            shortDescription
            slug
          }
        }
      }
    }
  `);

  const featuredBlogPost = data?.allContentfulFeaturedArticle?.nodes?.[0]?.featuredPost
  const image = getImage(featuredBlogPost?.heroImage)

  return (
    <Margin>
      <Section bgStyle="dark" showWave>
        <StyledLink to={`/blog/${featuredBlogPost.slug}`}>
          <PostContainer>
            <PostContent>
              <TitleTag>Featured Post</TitleTag>
              <H2>{featuredBlogPost?.title}</H2>
              <SubTitle>{featuredBlogPost.shortDescription}</SubTitle>
              <Tags>
                {featuredBlogPost?.topicTags?.slice(0, 5).map(tag => (
                  <Tag tag={tag} />
                ))}
              </Tags>
              <StyledMore to={`/blog/${featuredBlogPost.slug}`}>Read more <Arrow /></StyledMore>
            </PostContent>
            <PostImage>
              <ImageContainer>
                <StyledImage
                  image={image}
                  alt={featuredBlogPost?.heroImage?.description ?? "featured Post picture"}
                />
              </ImageContainer>
            </PostImage>
          </PostContainer>
        </StyledLink>
      </Section>
    </Margin>
  )
}


export default BlogHeaderSlice
