import React from 'react'
import styled from 'styled-components'


const TagContainer = styled.div`
  white-space: nowrap;
  padding: 0px 4px;
  margin-top: 8px;
  margin-right: 8px;
  border-radius: 8px;
  border: solid 2px;
  border-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.background.gray};
  height: fit-content;
  wrap: wrap;
`

const TagText = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 12px;
  padding: 0px 4px;
  margin: 0 auto;
`

const Tag = ({ tag }) => (
  <TagContainer>
    <TagText>{tag}</TagText>
  </TagContainer>
)

export default Tag;
