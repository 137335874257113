import React from 'react'
import styled from 'styled-components'
import { Row as LayoutRow, Section } from 'components/Layout'
import { Row, Col } from 'styled-bootstrap-grid'
import { FadeOnSlide } from 'components/Shared'
import Reuse from 'images/ads-landing/reuse.svg'
import Robot from 'images/ads-landing/robot.svg'
import Edit from 'images/ads-landing/edit.svg'
import Drag from 'images/ads-landing/drag.svg'
import Draft from 'images/ads-landing/draft.svg'
import Customize from 'images/ads-landing/customize.svg'
import DragAndDrop from '../../videos/drag-and-drop.mp4'
import Personalization from '../../videos/personalization.mp4'
import ReusableComponents from '../../videos/reusable-components.mp4'
import OneClickPublishing from '../../videos/one-click-publishing.mp4'
import TemplateStarters from '../../videos/template-starters.mp4'
import AIContent from '../../videos/ai-content.mp4'
import { SelfHostedVideo } from '../Landing'

const Title = styled.h2`
  text-align: center;
  margin: 48px;
  width: 80%;
`

const FeatureContainer = styled.div`
  width: 85%;
  margin: 48px 16px 112px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const FeatureTitle = styled.h3`
  font-size: 24px;
  text-align: left;
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  padding-bottom: 8px;
`

const FeatureText = styled.p`
  font-family: Exo;
  line-height: 1.3;
  margin: 0;
  padding: 4px 0 16px 0;
`

const IconContainer = styled.div`
  margin: 0 16px 0 0;
  padding: 20px 20px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.secondary.main};

  & svg {
    position: relative;
    top: 10%;
    left: 10%;
    width: 30px;
  }
`

const StyledEdit = styled(Edit)`
  top: 5% !important;
`

const StyledRobot = styled(Robot)`
  top: -10% !important;
  left: -0.25px !important;
`

const StyledReuse = styled(Reuse)`
  top: -2px !important;
  left: -1px !important;
`

const StyledDrag = styled(Drag)`
  top: 0% !important;
`

const StyledDraft = styled(Draft)`
  top: 10%;
  left: 5% !important;
  height: 25px !important;
`

const StyledCustomize = styled(Customize)`
  left: 5% !important;
  height: 25px !important;
`

/* const StyledMobile = styled(Mobile)`
  top: -5% !important;
  left: 12% !important;
  height: 35px !important;
`; */

const TemplateDesignFeaturesSlice = ({ sliceTitle }) => {
  const sliceContent = {
    title: sliceTitle || 'Easily create user-friendly AWS SES templates:',
    features: [
      {
        featureName: 'Drag and drop editor',
        featureDescription:
          'Experience smooth template designing with our intuitive drag-and-drop editor. Simply drag in the content blocks you need to create stunning email templates in minutes.',
        Icon: StyledDrag,
        Video: DragAndDrop,
      },
      {
        featureName: 'AI Assisted Design',
        featureDescription:
          'Leverage the power of AI in your design process. Our AI-assisted editor generates headlines, and even creates images, making your design process smoother and more efficient.',
        Icon: StyledRobot,
        Video: AIContent,
      },
      {
        featureName: 'Reusable components',
        featureDescription:
          'Save and re-use content blocks throughout all your templates. Simply save your favorite blocks to the library and use them in any template to save time and ensure consistency across templates',
        Icon: StyledReuse,
        Video: ReusableComponents,
      } /*
      {
        featureName: 'Responsive Templates',
        featureDescription: 'Ensure your emails look great on any device with our responsive templates. Design templates adapted to different screen sizes & ensure a seamless viewing experience for all your recipients.',
        Icon: StyledMobile,
        Video: ResponsiveTemplates,
      }, */,
      {
        featureName: 'Template Starters',
        featureDescription:
          'Get started quickly with our template starters. Choose from a variety of pre-designed templates and customize them to your needs. Our template starters are designed to save you time and effort.',
        Icon: StyledEdit,
        Video: TemplateStarters,
      },
      {
        featureName: 'One-Click Publishing to SES',
        featureDescription:
          'With just a click, your beautifully designed email templates get saved or go live on SES. Once connected to your AWS account, Semplates enables powerful, collaborative workflows for your team.',
        Icon: StyledDraft,
        Video: OneClickPublishing,
      },
      {
        featureName: 'Email Personalization',
        featureDescription:
          'Make your emails more engaging with our email personalization feature. Add a personal touch to your emails by including recipient-specific details, creating a more tailored and impactful communication.',
        Icon: StyledCustomize,
        Video: Personalization,
      },
    ],
  }

  const EditorFeature = ({ description }) => {
    const { featureName, featureDescription, Icon } = description
    return (
      <FeatureContainer>
        <IconContainer>{Icon && <Icon />}</IconContainer>
        <div>
          <FeatureTitle>{featureName}</FeatureTitle>
          <FeatureText>{featureDescription}</FeatureText>
        </div>
      </FeatureContainer>
    )
  }

  return (
    <Section>
      <LayoutRow>
        <Title>{sliceContent.title}</Title>
      </LayoutRow>
      <LayoutRow>
        <Row justifyContent="between">
          {sliceContent.features.map((feature, idx) => (
            <Col md="5" sm="12" xs="12" key={idx}>
              <FadeOnSlide delay={200}>
                <SelfHostedVideo
                  src={feature.Video}
                  controls
                  autoPlay={false}
                />
                <EditorFeature description={feature} />
              </FadeOnSlide>
            </Col>
          ))}
        </Row>
      </LayoutRow>
    </Section>
  )
}

export default TemplateDesignFeaturesSlice
