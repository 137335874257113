import React from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const Burger = styled.div`
  display: block;
  cursor: pointer;

  ${media.lg`
    display: none;
  `}

  span {
    display: block;
    background: ${({ inverted, theme }) =>
      inverted ? 'white' : theme.palette.primary.main};
    width: 26px;
    height: 2px;
    margin: 5px 0;
    opacity: 1;
    transform-orign: center;
    transition: transform 0.25s ease-out, opacity 0.25s ease-out;

    &:first-of-type {
      transform: ${props =>
        props.open
          ? 'rotate(45deg) translate(1px, 5px)'
          : 'rotate(0) translate(0, 0);'};
      translate: ${props => (props.open ? '0 0' : 'center')};
    }

    &:nth-of-type(2) {
      opacity: ${props => (props.open ? '0' : '1')};
    }

    &:last-of-type {
      transform: ${props => (props.open ? 'rotate(-45deg)' : 'rotate(0)')};
      transform-origin: ${props => (props.open ? '0 0' : 'center')};
    }
  }
`

const HeaderBurger = ({ open, onClick, inverted }) => (
  <Burger open={open} inverted={inverted} onClick={onClick}>
    <span />
    <span />
    <span />
  </Burger>
)

export default HeaderBurger
