import React, { useEffect, useState } from "react"
import { NavContainer, NavigationItemH2, NavigationItemH3, NavigationItemH4 } from "../../utils/templates/styles"


export const TableOfContents = ({ headings, offset = 100 }) => {
  const [currentHeading, setCurrentHeading] = useState(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setCurrentHeading(entry.target.id);
          }
        });
      },
      { rootMargin: `-${offset}px 0px -${window.innerHeight - offset * 2}px 0px` }
    );

    headings.forEach((heading) => {
      const target = document.getElementById(heading.id);
      if (target) {
        observer.observe(target);
      }
    });

    return () => {
      headings.forEach((heading) => {
        const target = document.getElementById(heading.id);
        if (target) {
          observer.unobserve(target);
        }
      });
    };
  }, [headings, offset]);

  const scrollToElement = (id) => {
    if (typeof window !== "undefined") {
      const element = document.getElementById(id)
      if (element) {
        const elementPosition = element.getBoundingClientRect().top
        const offsetPosition = elementPosition + window.pageYOffset - offset
        window.scrollTo({ top: offsetPosition, behavior: 'smooth' })
        setCurrentHeading(id);
      }
    }
  }

  const getStyleForHeading = (heading) => {
    let NavigationItem;

    switch (heading.tagName) {
      case 'H2':
        NavigationItem = NavigationItemH2;
        break;
      case 'H3':
        NavigationItem = NavigationItemH3;
        break;
      default:
        NavigationItem = NavigationItemH4;
        break;
    }

    return (
      <NavigationItem
        type="button"
        key={heading.id}
        onClick={() => scrollToElement(heading.id)}
        active={currentHeading === heading.id ? 1 : 0}
      >
        {heading.text}
      </NavigationItem>
    );
  };

  return (
    <NavContainer>
      {headings.map(heading => getStyleForHeading(heading))}
    </NavContainer>
  )
}

export const extractHeadings = html => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const headings = []

  doc.querySelectorAll('h2, h3, h4').forEach((heading, index) => {
    // Generate an ID if not present
    const id =
      heading.id ||
      `heading-${index}-${heading.textContent
        .toLowerCase()
        .replace(/\s+/g, '-')}`
    headings.push({ id, text: heading.textContent, tagName: heading.tagName })

    // eslint-disable-next-line no-param-reassign
    heading.id = id
  })
  const updatedHtml = doc.body.innerHTML

  return { headings, updatedHtml }
}