import React from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { Link } from 'gatsby'

const BreadCrumbContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${media.lg`
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  `}
`

const BreadCrumbTextRow = styled.p`
  margin: 8px 0;
  & b {
    padding: 0 8px;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.text.primary};
`

const BreadCrumb = ({ categoryTitle, pageTitle, slug }) => {
  const prefix = 'Documentation'
  const separator = ' / '

  const pathParts = slug.split('/')
  const baseLink = '/documentation'
  const categoryLink =
    pathParts.length === 2 ? `${baseLink}/${pathParts[0]}` : `/${pathParts[1]}`
  return (
    <BreadCrumbContainer>
      <BreadCrumbTextRow>
        <StyledLink to={baseLink}>{prefix}</StyledLink>
        {categoryTitle && (
          <StyledLink to={categoryLink}>
            <b>{separator}</b>
            <b>{categoryTitle}</b>
          </StyledLink>
        )}
      </BreadCrumbTextRow>
      {pageTitle && (
        <BreadCrumbTextRow>
          <b>{separator}</b>
          <b>{pageTitle}</b>
        </BreadCrumbTextRow>
      )}
    </BreadCrumbContainer>
  )
}

export default BreadCrumb
