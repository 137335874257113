import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'

const LongTitle = styled.h1`
  font-family: Exo;
  font-size: 36px;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  letter-spacing: 0em;
  padding: 0;
  margin: 0;
  ${media.md`
    font-size: 40px;
  `}
`

export default LongTitle
