/* eslint-disable import/no-unresolved */
import React, { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import { Section } from 'components/Layout'
import { media } from 'styled-bootstrap-grid'
import { graphql, useStaticQuery } from 'gatsby'
import { useFlexSearch } from 'react-use-flexsearch'
import Prev from 'images/arrow-circle-left-blue.svg'
import Next from 'images/arrow-circle-right-blue.svg'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Grid, Pagination, Navigation } from 'swiper/modules'
import SearchBar from '../Shared/SearchBar'
import BlogCard from '../Blog/BlogCard'
import useWindowSize from '../../hooks/useGatsbyWindowSize'
import StyledTag from '../Shared/StyledTag'

import 'swiper/css'
import 'swiper/css/grid'
import 'swiper/css/navigation'
import 'swiper/css/pagination';
import '../../styles.css'


const StyledSwiper = styled(Swiper)`
  margin-top: 40px;
  min-height: 1100px;
`

const StyledNavigation = styled.div`
  display: flex;
  justify-content: center;
  gap: 160px;
`

const TitleTag = styled(StyledTag)`
  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  ${media.sm`
    margin-top: 40px;
  `}
`

const Container = styled.div`
  color: black;
  text-align: center;
  border-radius: 24px;
  ${media.lg`
    padding-right: 8px;
  `}
`

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  top: -36px;
  z-index: 10;
`

const SliceHeader = styled.div`
  max-width: 950px;
  margin: auto;
  margin-bottom: 40px;
`

const Subtitle = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.gray};
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
`

const Title = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeights.titleBold};
  color: ${({ theme }) => theme.palette.primary.main};
`

const StyledSwiperSlide = styled(SwiperSlide)`
  width: auto;
  display: flex;
  justify-content: center;
`

const ArticlesContainer = styled.div``

const Tags = styled.div`
  display: flex;
  margin-top: 12px;
  align-items: center;
  white-space: nowrap;
`

const TagContainer = styled.div`
  white-space: nowrap;
  padding: 0px 4px;
  margin: 4px;
  border-radius: 8px;
  border: solid 2px;
  cursor: pointer;
  color: ${({ theme, selected }) => selected === 1 ? '#FFF' : theme.palette.primary.main};
  border-color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme, selected }) => (selected === 1 ?
    theme.palette.primary.main :
    theme.palette.background.gray
  )};
  flex-wrap: wrap;
`

const TagText = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 12px;
  padding: 0px 4px;
  margin: 0 auto;
`

const SearchContainer = styled.div`
  position: sticky;
  min-height: 100px;
  width: 100%;
  top: 70px;
  z-index: 100;
  padding: 40px 4px 4px 4px;
  margin-left: -4px;
  margin-top: -40px;
  background-color: ${({ theme }) => theme.palette.background.gray};
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.1);
`

const StyledQuick = styled.p`
  margin: 0;
  margin-right: 4px;
  color: ${({ theme }) => theme.palette.primary.gray};
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
`

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`


const BlogSearchBarSlice = () => {
  const [selectedFilters, setSelectedFilters] = useState(['All'])
  const { width } = useWindowSize()
  const pagination = {
    dynamicBullets: true,
    clickable: true,
    renderBullet: (index, className) => (`<span class="${className}">${index + 1}</span>`)
  };
  const sliderRef = useRef(null)
  const data = useStaticQuery(graphql`
    query AllBlogsQuery {
      allContentfulBlogPost(sort: { fields: [firstPublishedAt], order: DESC }) {
        nodes {
          title
          childContentfulBlogPostContentTextNode {
            childMarkdownRemark {
              html
              timeToRead
            }
          }
          topicTags
          firstPublishedAt
          metaDescription {
            metaDescription
          }
          shortDescription
          heroImage {
            gatsbyImageData(quality: 80, width: 500, placeholder: NONE)
            description
          }
          slug
        }
      }
      localSearchBlog {
        index
        store
      }
    }
  `)

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return
    sliderRef.current.swiper.slidePrev()

    const element = document.getElementById('articles')
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [])

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return
    sliderRef.current.swiper.slideNext()

    const element = document.getElementById('articles')
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [])

  const [searchQuery, setSearchQuery] = useState('')
  const results = useFlexSearch(
    searchQuery,
    data.localSearchBlog.index,
    data.localSearchBlog.store
  )

  const articles = searchQuery.length > 0 ? data.allContentfulBlogPost.nodes.filter(article => (
    results.some(resultArticle => resultArticle.title === article.title)
  )) : data.allContentfulBlogPost.nodes

  const filteredArticles = articles.filter(article => {
    if (selectedFilters[0] === 'All') {
      return true;
    }
    return selectedFilters.every(element => article.topicTags.includes(element));
  })

  const sliceContent = {
    sliceTitle: [
      <Title>
        The Semplates-Blog.
        <br />
        Make emails great again!
      </Title>,
    ],
    subtitle:
      'All things email templating with Semplates & AWS SES.',
    tag: 'BLOG',
    quickFilters: ['All', 'AWS SES', 'AWS Lambda', 'Terraform', 'Python'],
  }

  const handleFilterClick = (filter) => {
    const isSelected = selectedFilters.includes(filter);
    if (filter === 'All') {
      setSelectedFilters(['All']);
      return;
    }
    if (!isSelected && selectedFilters.includes('All')) {
      setSelectedFilters([filter]);
      return;
    }
    if (isSelected && selectedFilters.length === 1) {
      setSelectedFilters(['All']);
      return;
    }
    if (isSelected && selectedFilters.length >= 1) {
      setSelectedFilters(selectedFilters.filter(item => item !== filter));
      return;
    }
    setSelectedFilters([...selectedFilters, filter]);

  };

  const Filter = ({ filter }) => (
    <TagContainer selected={selectedFilters.includes(filter) ? 1 : 0} onClick={() => handleFilterClick(filter)}>
      <TagText>{filter}<span style={{ display: selectedFilters.includes(filter) ? '' : 'none' }}> &nbsp; ✕</span></TagText>
    </TagContainer>
  )

  const calculateSlides = () => {
    if (width > 1280) return 3
    if (width > 860) return 2
    return 1
  }

  const slides = calculateSlides()

  return (
    <Section>
      <Container id="articles">
        <TitleTag>{sliceContent.tag}</TitleTag>
        <SliceHeader>
          {sliceContent.sliceTitle}
          <Subtitle>{sliceContent.subtitle}</Subtitle>
        </SliceHeader>
      </Container>
      <SearchContainer>
        <SearchBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          placeholder="Search for a topic..."
        />
        <Tags>
          <StyledQuick>Quick filters:</StyledQuick>
          <TagsContainer>
            {sliceContent.quickFilters.map(filter => <Filter filter={filter} />)}
          </TagsContainer>
        </Tags>
      </SearchContainer>
      <ArticlesContainer id="blog" style={filteredArticles.length === 0 ? { display: 'none' } : {}}>
        <StyledSwiper
          ref={sliderRef}
          spaceBetween={20}
          slidesPerView={slides}
          slidesPerGroup={slides}
          grid={{ rows: 2, fill: 'row' }}
          pagination={pagination}
          modules={[Grid, Pagination, Navigation]}
          className="mySwiper"
        >
          {
            filteredArticles.map(article => (
              <StyledSwiperSlide>
                <BlogCard post={article} slideItem />
              </StyledSwiperSlide>
            ))
          }
        </StyledSwiper>

        <StyledNavigation style={filteredArticles.length <= 6 ? { display: 'none' } : {}}>
          <Button type="button" className="prev-arrow" onClick={handlePrev}>
            <Prev />
          </Button>
          <Button type="button" className="next-arrow" onClick={handleNext}>
            <Next />
          </Button>
        </StyledNavigation>
      </ArticlesContainer>
    </Section>
  )
}

export default BlogSearchBarSlice
