import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Section } from 'components/Layout'
import { media } from 'styled-bootstrap-grid'
import axios from 'axios'
import { Link } from 'gatsby'
import Arrow from 'images/arrow-forward-yellow.svg'
import StyledTag from '../Shared/StyledTag'

const IFrame = styled('iframe')`
  border: none;
  border-radius: 8px;
  width: 100% !important;
  overflow: hidden;
  ${media.xs`
    height: 90vh !important;
  `}
`

const TitleTag = styled(StyledTag)`
  margin: auto;
  margin-bottom: 20px;
  margin-top: 40px;
  padding: 2px 12px;
`

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.background.gray};
  color: black;
  text-align: center;
  border-radius: 24px;
  padding: 16px;
  position: relative;
  width: calc(100% - 32px);
  ${media.xs`
  right: 8px;
    width: calc(100% - 8px);
    padding: 16px 12px;
  `}
`

const Grid = styled.div`
  display: grid;
  grid-gap: 12px;
  ${media.lg`
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
  `}
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  ${media.xs`
    gap: 12px;
  `}
`

const FrameContainer = styled.div`
  position: relative;
`

const DiscoverLink = styled(Link)`
  margin-top: 28px;
  display: flex;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes.body1};
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-decoration: none;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: calc(50% - 116px);
    width: 0;
    height: 2px;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    transition: width 0.25s ease-out;
  }
  &:hover::after {
    width: 232px; /* Expand to full width */
  }
`

const FrameCover = styled.div`
  color: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 8px;
  display: grid;
  place-items: center;
  background-color: ${({ theme }) => theme.palette.background.dark};
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.25s ease;
  &:hover {
    opacity: 0.9;
  }
`

const StyledArrow = styled(Arrow)`
  margin-top: 4px;
`

const SliceHeader = styled.div`
  max-width: 950px;
  margin: auto;
  margin-bottom: 72px;
`

const Subtitle = styled.h3`
  text-align: center;
`

const Title = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeights.titleBold};
  color: ${({ theme }) => theme.palette.primary.main};
`

const StyledSectionContainer = styled.div`
  margin-top: -4px;
`

const adjustIframeHeight = iframe => {
  try {
    if (iframe && iframe.contentWindow.document.body) {
      const iframeContentHeight =
        iframe.contentWindow.document.body.scrollHeight
      // eslint-disable-next-line no-param-reassign
      iframe.style.height = `${iframeContentHeight}px`
    }
  } catch (error) {
    throw error
  }
}

const renderTemplateGrid = templates => {
  // Create columns as arrays
  const columns = [[], [], []]

  // Distribute templates across columns
  templates.forEach((template, index) => {
    // Calculate the column index
    const columnIndex = index % 3
    columns[columnIndex].push(template)
  })

  // Function to create iframes for each template
  const createIframes = (templateSubset, columnIndex) =>
    templateSubset.map((template, index) => (
      <FrameContainer key={`${columnIndex}-${index}`}>
        <Link to={`/editor?template=${encodeURIComponent(template.title)}`}>
          <FrameCover>Try it in the editor</FrameCover>
        </Link>
        <IFrame
          srcDoc={template.html_code}
          onLoad={e => adjustIframeHeight(e.target)}
          scrolling="no"
          title="Semplates example template"
        />
      </FrameContainer>
    ))

  // Create column elements
  return (
    <Grid>
      {columns.map((column, columnIndex) => (
        <Column key={columnIndex}>{createIframes(column, columnIndex)}</Column>
      ))}
    </Grid>
  )
}

const TemplateStartersSlice = () => {
  const [templates, setTemplates] = useState([])

  useEffect(() => {
    const loadTemplates = async () => {
      try {
        const starters = (await axios.get('https://api.semplates.io/starters')).data.slice(0, 5)
        // rearrange order
        setTemplates([...starters.slice(0,2), starters[3], starters[2], ...starters.slice(4,5)])
      } catch (error) {
        throw error
      }
    }
    loadTemplates()
  }, [])

  const sliceContent = {
    sliceTitle: [
      <Title>
        Quickly design beautiful AWS SES Email Templates
        <br />
        or use one of our ready-made Template Starters
      </Title>,
    ],
    subtitle:
      "No-code, pixel-perfect, and responsive Email Design with Semplates' Drag & Drop Builder",
    tag: 'TEMPLATE REPOSITORY',
    editorPath: '/editor/?template=Semplates%20Demo%20Template',
  }

  return (
    <StyledSectionContainer>
      <Section bgStyle="dark">
        <Container>
          <TitleTag>{sliceContent.tag}</TitleTag>
          <SliceHeader>
            {sliceContent.sliceTitle}
            <Subtitle>{sliceContent.subtitle}</Subtitle>
          </SliceHeader>
          {renderTemplateGrid(templates)}
        </Container>
        <DiscoverLink to={sliceContent.editorPath}>
          Try the editor for yourself&nbsp;&nbsp;
          <StyledArrow />
        </DiscoverLink>
      </Section>
    </StyledSectionContainer>
  )
}

export default TemplateStartersSlice
