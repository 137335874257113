import React from 'react'
import styled from 'styled-components'

const SearchResultLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.text.primary};
`

const SearchResultItem = styled.div`
  width: calc(100% - 32px);
  padding: 16px 0;
  border-top: 1px solid;
  border-color: ${({ theme }) => theme.palette.border.dark};
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  & p {
    margin: 0;
    padding: 0;
  }
`

const TimeDisclaimer = styled.p`
  text-align: right;
  width: 70px;
  color: ${({ theme }) => theme.palette.secondary.main};
`

const ResultTitle = styled.p`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  letter-spacing: 0.05em;
  margin: 0;
  padding: 0;
`

const SearchResultSlice = ({ post: { title, subtitle, timeToRead, slug } }) => {
  const timeCaption = `${timeToRead.toString()} min`
  return (
    <SearchResultLink href={slug}>
      <SearchResultItem>
        <div>
          <ResultTitle>{title}</ResultTitle>
          <p>{subtitle}</p>
        </div>
        <TimeDisclaimer>{timeCaption}</TimeDisclaimer>
      </SearchResultItem>
    </SearchResultLink>
  )
}

export default SearchResultSlice
