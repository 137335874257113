import React from 'react'
import styled from 'styled-components'
import Search from 'images/search.svg'
import { media } from 'styled-bootstrap-grid'

const Bar = styled.div`
  border: none;
  width: calc(100% - 48px);
  border-radius: 12px;
  & input {
    border-radius: 8px;
    width: calc(100% - 16px);
    font-family: Roboto;
    outline: none;
    margin: 0;
    height: 40px;
    padding: 4px 16px 4px 48px;
    font-style: normal;
    font-weight:500;
    color: ${({ theme }) => theme.palette.text.primary};
    border-color: ${({ theme }) => theme.palette.border.gray};
    ${({ searchBarClosed }) => searchBarClosed && `
      border: none;
      background-color: transparent;
      `}
      ${media.md`
      background-color: inherit;
      border: solid 1px; 
    `}
  }
`

const StyledSearch = styled(Search)`
  position absolute;
  margin-top: 16px;
  margin-left: 16px;
`


const SearchBar = ({ searchQuery, setSearchQuery, placeholder, searchBarClosed }) => (
  <Bar searchBarClosed={searchBarClosed}>
    <StyledSearch />
    <input
      value={searchQuery}
      onInput={e => setSearchQuery(e.target.value)}
      type="text"
      placeholder={placeholder}
      name="s"
    />
  </Bar>
)

export default SearchBar
