import React from 'react'
import styled from 'styled-components'
import { Col, Row, Section } from 'components/Layout'
import { media } from 'styled-bootstrap-grid'
import { FadeOnSlide } from 'components/Shared'

const StyledRow = styled(Row)`
  flex-direction: column;
  align-items: center;

  ${media.lg`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  `}
`

const PaddedCol = styled(Col)`
  max-width: 350px;
  padding: 32px 0;

  ${media.lg`
    padding: 0;

    &:not(:last-of-type) {
      padding-right: 32px;
    }
  `}
`

const StyledTitle = styled.h2`
  font-size: 28px;
`

const StyledCaption = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.primary.main};
`

const ClaimsSlice = () => {
  const sliceContent = [
    {
      title: 'Stunning Emails for Amazon SES',
      caption: 'Visual Template Editor',
      text: 'Choose a template from our starter library or create, upload, and maintain your own AWS SES email templates with our intuitive visual editor. Keep your branding consistent and eye-catching with re-usable components, AI-generated content & more.',
    },
    {
      title: 'Happy Developers, Great Collaboration',
      caption: 'Team Enablement',
      text: 'Empower your entire team with our user-friendly interface, streamlining email design and management. Say goodbye to the back-and-forth between product and development teams and hello to seamless collaboration!',
    },

    {
      title: 'Uncompromising Data Security',
      caption: 'Protect Sensitive Information',
      text: 'Ensure the highest level of data protection with Semplates and AWS SES. Our platform is compliant with strict industry regulations, providing peace of mind when handling user data. Combine effortless email management and uncompromised security.',
    },
  ]

  return (
    <Section bgStyle="light">
      <StyledRow>
        {sliceContent.map((claim, index) => (
          <PaddedCol key={index}>
            <FadeOnSlide delay={200 * index}>
              <StyledCaption>{claim.caption}</StyledCaption>
              <StyledTitle>{claim.title}</StyledTitle>
              <p>{claim.text}</p>
            </FadeOnSlide>
          </PaddedCol>
        ))}
      </StyledRow>
    </Section>
  )
}

export default ClaimsSlice
