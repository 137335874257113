import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Arrow from 'images/arrow-forward.svg'
import Tag from './Tag'
import useWindowSize from '../../hooks/useGatsbyWindowSize'

const CardContainer = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 520px;
  width: auto;
  max-width: 500px;
  border-radius: ${({ theme }) => theme.borders.radius};
  background-color: ${({ theme }) => theme.palette.background.main};
  color: ${({ theme }) => theme.palette.text.primary};
  transition: all 1s ease;
  text-decoration: none !important;
  overflow: hidden;
  &:hover {
    box-shadow: ${({ theme }) => theme.shadows.extralight};
  }
  @media (max-width: 480px) {
    width:  calc(100vw - 48px);
  }
`

const CardContent = styled.div`
  padding: 16px 16px 12px 16px;
  flex-direction: column;
  flex-grow: 1;
  color: black;
  font-weight: 400;
  overflow: hidden;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.palette.primary.dark};
  font-size: 20px;
  margin-top: 8px;
  margin-bottom: 16px;
`

const ImageContainer = styled.div`
  border-top-left-radius: ${({ theme }) => theme.borders.radius};
  border-top-right-radius: ${({ theme }) => theme.borders.radius};
  display: grid;
  place-items: center;
  padding-left: auto;
  padding-right: auto;
  height: 200px;
  overflow: hidden;
  @media (max-width: 480px) {
    height: 150px;
  }
`

const StyledMore = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 12px;
  line-height: 3.2;
  position: absolute;
  bottom: 4px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap 16px;
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  bottom: 40px;
  max-height: 80px;
  overflow: hidden;
  width: calc(100% - 32px);
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.palette.background.gray};
`

const Description = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: ${({ theme }) => theme.fontSizes.body2};
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  line-height: 20px;
  max-height: 60px;
`

const BlogCard = ({ post, slideItem }) => {
  const image = getImage(post?.heroImage)
  const description = post?.shortDescription
  const tagsRef = useRef(null)
  const [tagsOutside, setTagsOutside] = useState(0)
  const [tagsWithInFirstLines, setTagsWithinFirstLines] = useState(post?.topicTags?.length)
  const { width } = useWindowSize()

  useEffect(() => {
    const tagsContainer = tagsRef.current
    if (!tagsContainer) return

    const containerWidth = tagsContainer.offsetWidth
    const tags = tagsContainer.children
    let totalWidth = 0
    let firstFull = false

    for (let i = 0; i < tags.length; i += 1) {
      if (!firstFull && (totalWidth + tags[i].offsetWidth + 8) < containerWidth) {
        totalWidth += (tags[i].offsetWidth + 8)
        // eslint-disable-next-line no-continue
        continue
      }
      if (!firstFull) {
        firstFull = true
        totalWidth = 0
      }
      if ((totalWidth + tags[i].offsetWidth + 8) < containerWidth) {
        totalWidth += (tags[i].offsetWidth + 8)
        // eslint-disable-next-line no-continue
        continue
      }

      setTagsOutside(tags.length - i)
      setTagsWithinFirstLines(i)
      break
    }
  }, [post?.topicTags, width])

  const { title, slug } = post
  return (
    <CardContainer slideItem={slideItem} to={`/blog/${slug}`}>
      <ImageContainer slideItem={slideItem}>
        <GatsbyImage
          image={image}
          alt={post?.heroImage?.description ?? title}
        />
      </ImageContainer>
      <CardContent>
        <Title>{title}</Title>
        {title.length < 100 ? <Description>{description}</Description> : <></>}
        <Tags ref={tagsRef}>
          {post?.topicTags.map((tag, index) => {
            if ((index + 1) === tagsWithInFirstLines && tagsOutside > 0) return <Tag key="extra-tag" tag={`+${tagsOutside + 1}`} />
            if ((index + 1) >= tagsWithInFirstLines && tagsOutside > 0) return null
            return (<Tag tag={tag} />)
          })}
        </Tags>
        <FlexContainer>
          <StyledMore>Read more <Arrow alt="Arrow Button"/></StyledMore>
        </FlexContainer>
      </CardContent>
    </CardContainer>
  )
}

export default BlogCard
