import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { Col, Row, Section } from 'components/Layout'
import { ActionButton, FadeOnSlide } from 'components/Shared'
import { media } from 'styled-bootstrap-grid'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import useWindowSize from '../../hooks/useGatsbyWindowSize'

const ButtonRow = styled(Row)`
  flex-direction: column;

  ${media.lg`
    flex-direction: row;
    justify-content: center;
  `}
`

const StyledRow = styled(Row)`
  align-items: flex-start;
  flex-direction: column;

  ${media.lg`
    flex-direction: row;
    justify-content: space-between;
  `}
`

const TextCol = styled(Col)`
  justify-content: flex-start;
  margin: 24px 0;

  ${media.lg`
    margin: 32px
  `}
`

const ImageCol = styled(TextCol)`
  align-items: center;
`

const StyledImage = styled(GatsbyImage)`
  box-shadow: ${({ theme }) => theme.shadows.main};
  border-radius: 8px;
`

const BusinessCasesSlice = () => {
  const [tabIndex, setTabIndex] = useState(0)

  const sliceContent = {
    title: 'How Semplates helps your business',
    tabContent: [
      {
        title: 'Transactional emails for the your next SaaS-unicorn',
        text: 'Give your business a face at every user touchpoint: confirmation, registration or password reset emails – all in your brand. You can trigger emailing events from anywhere in your software architecture via our API or AWS SES. If you are using AWS services for your product already, then Semplates will integrate seamlessly in your existing cloud development processes.',
        buttonText: 'Transactional mails for start-ups',
        buttonTextShort: 'Transactional mails',
        imageAlias: 'saasStartup',
        imageAlt:
          'Happy startup employees building branded, personalized email templates with Semplates.',
      },
      {
        title:
          'Manage dozens of templates across multiple AWS SES accounts through Semplates',
        text: 'Manage all your templates across multiple AWS SES accounts in one tool: Semplates helps your team manage their transactional mails. Your teams keeps the overview and ensures your company brings the same level of branding professionality to all your transactional email communication.',
        buttonText: 'Professional SES management',
        buttonTextShort: 'SES management',
        imageAlias: 'templateManagement',
        imageAlt:
          'Person managing multiple AWS SES accounts through Semplates and looking relaxed and joyful while doing so.',
      },
    ],
  }

  const data = useStaticQuery(graphql`
    query businessCaseQuery {
      saasStartup: file(relativePath: { eq: "saas-startup.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 600
            placeholder: NONE
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      templateManagement: file(
        relativePath: { eq: "template-management.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 600
            placeholder: NONE
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  const image = getImage(data[sliceContent.tabContent[tabIndex].imageAlias])
  const { width } = useWindowSize()
  const theme = useTheme()

  return (
    <Section bgStyle="light">
      <Row>
        <h2>{sliceContent.title}</h2>
      </Row>
      <ButtonRow>
        {sliceContent.tabContent.map((tabs, idx) => (
          <React.Fragment key={idx}>
            <ActionButton
              label={
                width >= theme.breakpoints.lg
                  ? tabs.buttonText
                  : tabs.buttonTextShort
              }
              onClick={() => setTabIndex(idx)}
              inactive={tabIndex !== idx}
            />
          </React.Fragment>
        ))}
      </ButtonRow>
      <StyledRow>
        <TextCol>
          <FadeOnSlide>
            <h2>{sliceContent.tabContent[tabIndex].title}</h2>
            <p>{sliceContent.tabContent[tabIndex].text}</p>
          </FadeOnSlide>
        </TextCol>

        <ImageCol>
          <StyledImage
            image={image}
            alt={sliceContent.tabContent[tabIndex].imageAlt}
          />
        </ImageCol>
      </StyledRow>
    </Section>
  )
}

export default BusinessCasesSlice
