import React, { useCallback, useRef } from 'react'
import styled, { useTheme } from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper/modules'
import Prev from 'images/arrow-circle-left.svg'
import Next from 'images/arrow-circle-right.svg'
import CaseStudyCard from './CaseStudyCard'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import useWindowSize from '../../hooks/useGatsbyWindowSize'

const StyledSwiperSlide = styled(SwiperSlide)`
  width: auto;
`

const StyledSwiper = styled(Swiper)`
  margin-top: 40px;
`

const StyledNavigation = styled.div`
  display: flex;
  gap: 20px;
`

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`

const ButlletAndNavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`

const CaseStudySlider = ({ caseStudies }) => {
  const theme = useTheme()
  const sliderRef = useRef(null)
  const { width } = useWindowSize()

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return
    sliderRef.current.swiper.slidePrev()
  }, [])

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return
    sliderRef.current.swiper.slideNext()
  }, [])

  const calculateSlides = () => {
    if (caseStudies.length === 4 && width > 1300) return 4
    if (caseStudies.length === 3 && width > 980) return 3
    if (caseStudies.length === 2 && width > 660) return 2
    return 'auto'
  }

  const slides = calculateSlides()

  return (
    <>
      <StyledSwiper
          ref={sliderRef}
          spaceBetween={20}
          slidesPerView={slides}
          pagination={{ el: '.swiper-custom-pagination' }}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {caseStudies.map(caseStudy => (
            <StyledSwiperSlide>
              <CaseStudyCard post={caseStudy} slideItem />
            </StyledSwiperSlide>
          ))}
      </StyledSwiper>
      <ButlletAndNavigationContainer
        style={slides !== 'auto' ? { display: 'none' } : {}}
      >
        <div
          style={{
            '--swiper-pagination-color': theme.palette.secondary.main,
            '--swiper-pagination-bullet-inactive-color':
            theme.palette.border.gray,
          }}
          className="swiper-custom-pagination"
        />
        <StyledNavigation>
          <Button type="button" className="prev-arrow" aria-label="Go to previous Case Study Item" onClick={handlePrev}>
            <Prev />
          </Button>
          <Button type="button" className="next-arrow" aria-label="Go to next Case Study Item" onClick={handleNext}>
            <Next />
          </Button>
        </StyledNavigation>
      </ButlletAndNavigationContainer>
    </>
  )
}

  export default CaseStudySlider