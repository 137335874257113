import React from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { GatsbyImage } from 'gatsby-plugin-image'
import Flag from 'images/flag.svg'

export const ImageContainer = styled.div`
  border-radius: 24px;
  ${media.lg`
    padding: 0 200px;
    background-color: ${({ theme }) => theme.palette.secondary.light};
  `}
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

export const StyledImage = styled(GatsbyImage)`
  width: 100%;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.borders.radius};
`

export const SubHeader = styled.div`
  margin-top: 40px;
  ${media.md` 
    display: grid;
    grid-template-columns: auto 250px;
    gap: 20px;
  `}
`

export const Content = styled.div`
  gap: 80px;
  ${media.lg`
    display: grid;
    grid-template-columns: 3fr 1fr;
  `}
`

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-items: flex-start;
  margin-top: -8px;
`

const TagContainer = styled.div`
  white-space: nowrap;
  padding: 0px 4px;
  margin: 4px;
  border-radius: ${({ theme }) => theme.borders.radius};
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.secondary.main};
  height: fit-content;
`

const TagText = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 12px;
  padding: 0px 4px;
  margin: 0 auto;
`

export const SubHeaderTime = styled.div`
  text-align: right;
  font-size: ${({ theme }) => theme.fontSizes.body2} !important;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.gray};
`

export const TimeToRead = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 30px;
  margin-top: 4px;
`

export const SmallText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.body2} !important;
  color: ${({ theme }) => theme.palette.primary.gray};
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-weight: 500;
`

export const Navigation = styled.div`
  display: none;
  ${media.lg`
    position: sticky;
    top: 80px;
    display: block;
  `}
`

export const NavigationHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.main};
  & p {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.fontSizes.body1};
    font-family: ${({ theme }) => theme.fontFamilies.secondary};
    margin-left: 8px;
  }
`

export const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const NavigationItemH3 = styled.button`
  color: ${({ theme, active }) => active === 1 ? theme.palette.primary.main : theme.palette.primary.gray};
  background-color: transparent;
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-size: 14px;
  font-weight: 500;
  border: 0;
  text-align: left;
  border-left: 2px solid;
  padding: 8px;
  cursor: pointer;
`

export const NavigationItemH4 = styled.button`
  color: ${({ theme, active }) => active === 1 ? theme.palette.primary.main : theme.palette.primary.gray};
  background-color: transparent;
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-size: 14px;
  font-weight: 500;
  border: 0;
  text-align: left;
  border-left: 2px solid;
  padding: 8px;
  padding-left: 20px;
  cursor: pointer;
`

export const NavigationItemH2 = styled.button`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme, active }) => active === 1 ? theme.palette.primary.main : theme.palette.primary.gray};
  background-color: transparent;
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-size: ${({ theme }) => theme.fontSizes.body2};
  border: 0;
  text-align: left;
  padding-left: 0;
  margin: 8px 0;
  cursor: pointer;
`

export const Timestamp = styled.p`
  color: ${({ theme }) => theme.palette.secondary.main};
`

export const StyledFlag = styled(Flag)`
  color: ${({ theme }) => theme.palette.primary.main};
  padding-top: 4px;
`

export const H2 = styled.h2`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: ${({ theme }) => theme.fontWeights.titleBold};
`

export const MarginBottom = styled.p`
  margin-bottom: 80px;
  color: ${({ theme }) => theme.palette.primary.gray};
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-size: ${({ theme }) => theme.fontSizes.body2};
  font-weight: 500;
`

export const Tag = ({ tag }) => (
  <TagContainer>
    <TagText>{tag}</TagText>
  </TagContainer>
)

export const Title = styled.h1`
  font-weight: ${({ theme }) => theme.fontWeights.titleBold};
  color: ${({ theme }) => theme.palette.primary.main};
`

export const StyledSubtitle = styled.div`
  margin-top 40px;
  background-color: ${({ theme }) => theme.palette.secondary.gray};
  border-left: 6px solid;
  border-color: ${({ theme }) => theme.palette.secondary.main};
  padding: 8px;
  & li {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  & p {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-family: ${({ theme }) => theme.fontFamilies.secondary};
  }
`

export const ShareContainer = styled.div`
  margin-top: 16px;
`

export const ArticleContent = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.palette.text.gray};

  & h2 {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: ${({ theme }) => theme.fontWeights.titleBold};
  }

  & h3 {
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: ${({ theme }) => theme.fontFamilies.main};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 20px;
  }

  & h4 {
    font-size: 20px;
    color: ${({ theme }) => theme.palette.primary.main};
  }

  & p {
    padding: 8px 0 16px 0;
    font-weight: 500;
    & a {
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      text-decoration: none;
      color: #064861;
    }
  }

  & ul {
    & a {
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      text-decoration: none;
      color: #064861 !important;
    }
  }

  & deckgo-highlight-code {
    margin-bottom: 32px;
  }

  & img {
    max-width: 100%;
  }
`
