import React, { useState } from "react";
import styled from "styled-components";
import { media } from 'styled-bootstrap-grid';

const Area = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: white;
  border-radius: ${({ theme }) => theme.borders.radiusLg};
  min-height: 250px;
  height: 100%;
  grid-area: ${({ gridArea }) => gridArea};
  padding: 16px 8px 0px 8px;
  height: calc(100% - 16px);
  ${media.lg`
    padding: 32px 32px 0px 32px;
    height: calc(100% - 32px);
    flex-direction: ${({ imagePosition }) => imagePosition === 'right' ? 'row' : 'column'};
  `}
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  ${media.lg`
    flex-direction: row;
    justify-content: center;
  `}
`;

const TextRow = styled(Row)`
  margin: 8px 16px 0px 16px;
  ${media.lg`
    margin: 8px 64px;
  `};
`;

const StyledActionButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 8px;
  padding: 8px 16px;
  border-radius: ${({ theme }) => theme.borders.radius};
  text-decoration: none;
  background-color: ${({ active, theme }) =>
    active ? theme.palette.primary.main : 'transparent'};
  color: ${({ active, theme }) =>
    active ? 'white' : theme.palette.primary.main};
  border: 2px solid ${({ theme }) => theme.palette.primary.main};
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.25s ease-in;
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: white;
  }
`;

const StyledText = styled.p`
  line-height: 1.5;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const VideoWrapper = styled.div`
  width: auto;
  margin: 0 auto;
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.shadows.main};
  overflow: hidden;
  margin-top: 8px;
  ${media.lg`
    margin-top: 32px;
  `};
`;

const StyledVideo = styled.video`
  width: 100%;
  margin-left: -1px;
  display: block;
  border-radius: ${({ theme }) => theme.borders.radius};
  height: 450px;
  ${media.lg`
    height: 450px;
  `};
`;


const TabBarCard = ({ gridArea, tabBarContent = [] }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const getButtons = () =>
    tabBarContent.map((content, index) => (
      <StyledActionButton
        key={index}
        active={index === activeIndex}
        onClick={() => setActiveIndex(index)}
      >
        {content.title}
      </StyledActionButton>
    ));

  const activeContent = tabBarContent[activeIndex];


  return (
    <Area gridArea={gridArea}>
      <Row>{getButtons()}</Row>
      <TextRow>
        <StyledText>{activeContent.text}</StyledText>
      </TextRow>
      <VideoWrapper>
        <StyledVideo key={activeContent.videoData} muted loop controls>
          <source src={activeContent.videoData} type="video/mp4" />
          Your browser does not support the video tag.
        </StyledVideo>
      </VideoWrapper>
    </Area>
  );
};

export default TabBarCard;
