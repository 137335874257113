import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { SignupSlice } from 'components/Slices'
import Header from './Header'
import Footer from './Footer'

const Main = styled.main`
  margin: 72px auto 0 auto;
`

const Layout = ({ lightHeader, noSignupSlice, children, signupSliceTitle }) => (
  <>
    <Header lightbackground={lightHeader} />
    <Main>{children}</Main>
    {!noSignupSlice && <SignupSlice sliceTitle={signupSliceTitle} />}
    <Footer />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
