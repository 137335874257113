import React from 'react'
import styled, { useTheme } from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Wave from '../../images/wave-ab-testing.svg'

const OuterContainer = styled.div`
  margin: 0;
  position: relative;
  width: 100%;
  padding-bottom: ${({ noBottomPadding }) => (noBottomPadding ? 0 : '32px')};
  padding-top: ${({ noTopPadding }) => (noTopPadding ? 0 : '24px')};
  background-color: ${({ bgColor }) => bgColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ background, theme }) =>
    background
      ? `linear-gradient(to bottom, ${theme.palette.background.dark} 150px, ${theme.palette.background.gray} 150px)`
      : ''};
  ${media.sm`
    background: ${({ background, theme }) =>
      background
        ? `linear-gradient(to bottom, ${theme.palette.background.dark} 20vw, ${theme.palette.background.gray} 20vw)`
        : ''};
  `}
  ${media.md`
    background: ${({ background, theme }) =>
      background
        ? `linear-gradient(to bottom, ${theme.palette.background.dark} 30vw, ${theme.palette.background.gray} 30vw)`
        : ''};
  `}
  ${media.xl`
    background: ${({ background, theme }) =>
      background
        ? `linear-gradient(to bottom, ${theme.palette.background.dark} 400px, ${theme.palette.background.gray} 400px)`
        : ''};
  `}
`

const StyledWave = styled(Wave)`
  position: absolute;
  top: 99%;
  left: 0;
  width: 100vw;
`

const StyledTopWave = styled(Wave)`
  z-index: 20;
  position: relative;
  bottom: 10px;
  left: 0;
  width: 100vw;
  transform: rotate(180deg);
`

const GridContainer = styled.div`
  max-width: 1350px;
  margin: 48px 16px 16px 16px;
  width: calc(100% - 32px);
  color: ${({ bgStyle, theme }) =>
    bgStyle === 'dark'
      ? theme.palette.text.secondary
      : theme.palette.text.primary};

  ${media.lg`
    margin: ${({ noBottomMargin }) =>
      noBottomMargin ? '16px 32px 0px 32px' : '24px 32px 24px 32px'};
    margin-top: ${({ noTopMargin }) => (noTopMargin ? 0 : '16px')};
    width: calc(100% - 64px);
  `}
`

const Section = ({ bgStyle, showWave, showTopWave, children }) => {
  const theme = useTheme()
  // bgStyle: one of {none, light, dark}
  let bgColor = theme.palette.background.gray
  let background = 0
  if (bgStyle && bgStyle === 'light') {
    bgColor = theme.palette.background.light
  } else if (bgStyle && bgStyle === 'white') {
    bgColor = 'white'
  } else if (bgStyle && bgStyle === 'dark') {
    bgColor = theme.palette.background.dark
  } else if (bgStyle && bgStyle === 'darkTop') {
    background = 1
  }

  return (
    <OuterContainer
      background={background}
      bgColor={bgColor}
      noBottomPadding={showWave ? 1 : 0}
      noTopPadding={showTopWave ? 1 : 0}
    >
      {showTopWave && <StyledTopWave />}
      <GridContainer
        bgStyle={bgStyle}
        noBottomMargin={showWave ? 1 : 0}
        noTopMargin={showTopWave ? 1 : 0}
      >
        {children}
      </GridContainer>
      {showWave && <StyledWave />}
    </OuterContainer>
  )
}

export default Section
