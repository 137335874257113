import React, { useState } from 'react'
import styled from 'styled-components'
import { useFlexSearch } from 'react-use-flexsearch'
import { media } from 'styled-bootstrap-grid'
import { graphql, useStaticQuery } from 'gatsby'
import SearchBar from '../Shared/SearchBar'
import { SearchResultSlice } from '../Slices'

const SearchResults = styled.div`
  position: fixed;
  max-height: calc(100vh - 132px);
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.palette.background.gray};
  width: 100vw;
  left: 0;
  top: 132px;
  padding: 16px;
  ${media.md`
    width: calc(100% - 10px);
    padding: 0;
    margin: 16px;
    background-color; inherit;
    position: static;
    max-height: none
  `}
`
const Container = styled.div`
  z-index: 300;
  position: relative;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.extralight};
  ${({ searchBarClosed }) => searchBarClosed && 'box-shadow: none;'}
  ${media.md`
    box-shadow: ${({ theme }) => theme.shadows.extralight};
  `}
`

const DocumentationSearch = ({ searchBarClosed }) => {
  const data = useStaticQuery(graphql`
    query documentationArticlesQuery {
      localSearchDocumentation {
        index
        store
      }
    }
  `)
  const [searchQuery, setSearchQuery] = useState('')
  const results = useFlexSearch(
    searchQuery,
    data.localSearchDocumentation.index,
    data.localSearchDocumentation.store
  )

  return (
    <Container
      searchBarClosed={searchBarClosed}
    >
      <SearchBar
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        placeholder="Search for a topic..."
        searchBarClosed={searchBarClosed}
      />
      {searchQuery && (
        <SearchResults>
          {results.slice(0, 8).map(post => (
            <SearchResultSlice post={post} />
          ))}
        </SearchResults>
      )}
    </Container>
  )
}

export default DocumentationSearch
