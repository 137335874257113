import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery} from 'gatsby'
import { media } from 'styled-bootstrap-grid'
import { Section } from 'components/Layout'
import { FeatureCard, FeaturesEditorFeaturesGrid, TabBarCard } from 'components/Features'
import AiAssistedDesign from 'images/icons/ai-assisted-design.svg'
import EasilyMoveTemplates from 'images/icons/easily-move-templates.svg'
import EasyPublishProcess from 'images/icons/easy-publish-process.svg'
import SesTemplateImport from 'images/icons/ses-template-import.svg'
import SendEmailsEasily from 'images/icons/send-emails-easily.svg'

const Container = styled.div`
  scroll-margin-top: 100px;
`

const Tag = styled.p`
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: ${({ theme }) => theme.fontFamilies.main};
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: ${({ theme }) => theme.fontSizes.body2};
`

const Title = styled.h2`
  margin-top: 40px;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

const Clifhanger = styled.h6`
  margin-top: 80px;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

const GridContainer = styled.div`
  margin: 48px 0px;
  display: grid;
  grid-template-rows: 0.25fr 0.25fr 0.25fr 0.25fr;
  grid-template-areas:
    "main"
    "main"
    "main"
    "middle-left"
    "middle-right"
    "bottom-left"
    "bottom-center"
    "bottom-right"
  ;
  text-align: left;
  grid-gap: 32px;
  transition: all 0.25s ease-in-out;
  min-height: 1300px;
  height: 300vh;
  ${media.lg`
    margin: 72px 0px 0px 0px;
    min-height: 1300px;
    height: 180vh;
    grid-template-rows: 0.25fr 0.25fr 0.25fr 0.25fr;
    grid-template-areas:
      "main main main main"
      "main main main main"
      "middle-left middle-left middle-right middle-right"
      "bottom-left bottom-center bottom-center bottom-right"
    ;
  `}
  ${media.xxl`
    height: 110vh;
  `}
`;

const HeroContent = () => {
  const data = useStaticQuery(graphql`
    query EditorVideos {
      aiAssistedDesign: file(relativePath: { eq: "ai-assisted.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 90
            placeholder: NONE
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      easyPublishing: file(relativePath: { eq: "easy-publishing.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 90
            placeholder: NONE
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      sendEmail: file(relativePath: { eq: "send-email.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 90
            placeholder: NONE
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      dragAndDropEditor: file(relativePath: { eq: "features-drag-and-drop.mp4" }) {
        publicURL
      }
      responsiveTemplates: file(relativePath: { eq: "features-responsive-templates.mp4" }) {
        publicURL
      }
      templateManagement: file(relativePath: { eq: "features-template-management.mp4" }) {
        publicURL
      }
      previewValidation: file(relativePath: { eq: "preview-validation.mp4" }) {
        publicURL
      }
      testSending: file(relativePath: { eq: "test-sending.mp4" }) {
        publicURL
      }
    }
  `);
  
  const sliceContent = {
    titleClaim: [
      <span>Create stunning AWS SES Email Templates</span>,
      <br />,
      <span>with our Drag n Drop Email Editor</span>,
    ],
    tagText: 'TEMPLATE EDITOR',
    clifhangerText: 'and so much more...',
    cardContent: [
      {
        title: 'AI Assisted Design',
        text: 'Do not know where to start? We’ve got you covered. Create great AWS SES templates in a matter of seconds!',
        imageData: data.aiAssistedDesign,
        imagePosition: 'right',
        Icon: AiAssistedDesign,
        gridArea: 'middle-left'
      },
      {
        title: 'Efficient publishing process',
        text: 'Take advantage of our three-state model: Draft, Saved and Published. Easily move templates between states and publish them to your AWS account, once ready.',
        imageData: data.easyPublishing,
        imagePosition: 'right',
        Icon: EasyPublishProcess,
        gridArea: 'middle-right'
      },
      {
        title: 'SES Template Import',
        text: 'Import all of your existing AWS SES templates in only 3 clicks. Then edit your templates HTML within Semplates.',      
        imageData: undefined,
        Icon: SesTemplateImport,
        gridArea: 'bottom-left'
      },
      {
        title: 'Email Sending made easy',
        text: 'Easily send your templates to up to 50 recipients. Test your templates, or directly bulk send to your recipients.',
        imageData: data.sendEmail,
        imagePosition: 'right',
        Icon: SendEmailsEasily,
        gridArea: 'bottom-center',
        comingSoon: false
      },
      {
        title: 'Easily move templates between accounts',
        text: 'Seamlessly transfer email templates between AWS accounts. Semplates allows you to efficiently promote templates from development to production environments.',
        imageData: undefined,
        Icon: EasilyMoveTemplates,
        gridArea: 'bottom-right'
      }
    ],
    tabCardContent: [
      {
        "title": "✍️ Drag and Drop Editor",
        "text": "Easily design emails with our drag and drop editor. Create stunning layouts without any coding.",
        "videoData": data.dragAndDropEditor.publicURL
      },
      {
        "title": "🔥 25+ Responsive Templates",
        "text": "Access over 25 responsive templates that look great on any device. Customize them to fit your needs.",
        "videoData": data.responsiveTemplates.publicURL
      },
      {
        "title": "💼 Template Management",
        "text": "Efficiently manage all your email templates in one place. Organize, edit, and reuse templates easily.",
        "videoData": data.templateManagement.publicURL
      },
      {
        "title": "👀 Template Preview & Validation",
        "text": "Preview and validate your templates before sending. Ensure they look perfect on all devices.",
        "videoData": data.previewValidation.publicURL
      },
      {
        "title": "💌 Test Sending",
        "text": "Test your template designs by sending emails straight to your inbox with a few clicks.",
        "videoData": data.testSending.publicURL
      }            
    ]    
  }

  return (
      <Container id="editor">
        <Tag>{sliceContent.tagText}</Tag>
        <Title>{sliceContent.titleClaim}</Title>
        {sliceContent.subtitle}
        <GridContainer>
          <TabBarCard gridArea="main" tabBarContent={sliceContent.tabCardContent}/>
          {sliceContent.cardContent.map((card, idx) => (
            <FeatureCard key={idx} {...card} />
          ))}
        </GridContainer>
        <Clifhanger>{sliceContent.clifhangerText}</Clifhanger>
        <FeaturesEditorFeaturesGrid/>
      </Container>
  )
};

const FeaturesSliceEditor = () => (
    <Section bgStyle="dark">
      <HeroContent />
    </Section>
  )

export default FeaturesSliceEditor
