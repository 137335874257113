import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { Section } from 'components/Layout'
import { FeatureCard, TabBarCard } from 'components/Features'
import MultipleEnvironments from 'images/icons/multiple-environments.svg'
import AwsHealthCheck from 'images/icons/aws-health-check.svg'
import AwsAccountStatistics from 'images/icons/aws-account-statistics.svg'
import GranularIamPermissions from 'images/icons/granular-iam-permissions.svg'
import EmailSenderIdentities from 'images/icons/email-sender-identities.svg'

const Container = styled.div`
  scroll-margin-top: 100px;
`

const Tag = styled.p`
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: ${({ theme }) => theme.fontFamilies.main};
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: ${({ theme }) => theme.fontSizes.body2};
`

const Title = styled.h2`
  margin-top: 40px;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`
const GridContainer = styled.div`
  margin: 48px 0px;
  display: grid;
  grid-template-rows: 0.25fr 0.25fr 0.25fr 0.25fr;
  grid-template-areas:
    "main"
    "main"
    "main"
    "middle-left"
    "middle-right"
    "bottom-left"
    "bottom-center"
    "bottom-right"
  ;
  text-align: left;
  grid-gap: 32px;
  transition: all 0.25s ease-in-out;
  min-height: 1300px;
  height: 300vh;
  ${media.lg`
    margin: 72px 0px 0px 0px;
    min-height: 1300px;
    height: 180vh;
    grid-template-rows: 0.25fr 0.25fr 0.25fr 0.25fr;
    grid-template-areas:
      "main main main main"
      "main main main main"
      "middle-left middle-left middle-right middle-right"
      "bottom-left bottom-center bottom-center bottom-right"
    ;
  `}
  ${media.xxl`
    height: 110vh;
  `}
`;

const HeroContent = () => {
 const data = useStaticQuery(graphql`
      query FeaturesImages {
        senderIdentities: file(relativePath: { eq: "email-identities.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 90
              placeholder: NONE
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        accountStatistics: file(relativePath: { eq: "account-statistics.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 90
              placeholder: NONE
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        multipleAccounts: file(relativePath: { eq: "multiple-accounts.mp4" }) {
          publicURL
        }
        easySetup: file(relativePath: { eq: "easy-setup.mp4" }) {
          publicURL
        }
        identitiesManagement: file(relativePath: { eq: "identities-management.mp4" }) {
          publicURL
        }
        domainIdentities: file(relativePath: { eq: "domain-identities.mp4" }) {
          publicURL
        }
      }
  `);

  const sliceContent = {
    titleClaim: [
      <span>Never loose the oversight:</span>,
      <br />,
      <span>Organize all of your AWS SES templates</span>,
    ],
    tagText: 'SES MANAGEMENT',
    cardContent: [
      {
        title: 'Multiple environments, regions or client accounts',
        text: 'Connect multiple AWS accounts by adding IAM credentials and easily manage templates across different regions or accounts.',
        Icon: MultipleEnvironments,
        gridArea: 'middle-left'
      },
      {
        title: 'Add and authorize email sender identities',
        text: 'Simplify your email sending setup by authorizing and managing sender identities directly through our interface. Seamlessly integrate with your current email strategies.',      
        imageData: data.senderIdentities,
        imagePosition: 'right',
        Icon: EmailSenderIdentities,
        gridArea: 'middle-right'
      },
      {
        title: 'AWS Health Check',
        text: 'Ensure optimal operation of your AWS services with comprehensive health checks. Easily toggle between accounts to monitor the status and maintain efficiency.',      
        Icon: AwsHealthCheck,
        gridArea: 'bottom-left'
      },
      {
        title: 'AWS Account Statistics',
        text: 'Keep track of important metrics and statistics about your account’s bounce, complaint and reject rates to further adjust your templates.',
        Icon: AwsAccountStatistics,
        imageData: data.accountStatistics,
        imagePosition: 'right',
        gridArea: 'bottom-center',
        comingSoon: false
      },
      {
        title: 'Granular IAM Permissions',
        text: 'Fine-tune the control over your AWS services by defining specific actions Semplates can perform on your behalf, enhancing security and operational flexibility.',      
        Icon: GranularIamPermissions,
        gridArea: 'bottom-right'
      }
    ],
    tabCardContent: [
      {
        "title": "🔐 Multiple AWS Accounts",
        "text": "Manage multiple AWS accounts seamlessly in one platform. Switch between accounts effortlessly and control your resources across different environments.",
        "videoData": data.multipleAccounts.publicURL
      },
      {
        "title": "📥 Easy and guided SES Setup",
        "text": "Set up Amazon SES easily with our guided process. Follow step-by-step instructions for a smooth experience and start sending emails quickly.",
        "videoData": data.easySetup.publicURL
      },
      {
        "title": "📨 Email Identities Management",
        "text": "Manage your email identities effortlessly. Verify and monitor email addresses for Amazon SES to keep your communications secure.",
        "videoData": data.identitiesManagement.publicURL
      },
      {
        "title": "🌍 Domain Identities Management",
        "text": "Control and verify domain identities for email communications. Ensure your emails are trusted and pass spam filters.",
        "videoData": data.domainIdentities.publicURL
      }
    ]    
  }
  
  return (
      <Container id="management">
        <Tag>{sliceContent.tagText}</Tag>
        <Title>{sliceContent.titleClaim}</Title>
        {sliceContent.subtitle}
        <GridContainer>
          <TabBarCard gridArea="main" tabBarContent={sliceContent.tabCardContent}/>
          {sliceContent.cardContent.map((card, idx) => (
            <FeatureCard key={idx} {...card}/>
          ))}
        </GridContainer>
      </Container>
  )
};

const FeaturesSliceManagement = () => (
    <Section>
      <HeroContent />
    </Section>
  )

export default FeaturesSliceManagement
