import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import Switch from "react-switch"
import { Col, Row, Section } from 'components/Layout'
import { LinkButton, StyledTag, Label } from 'components/Shared'
import { media } from 'styled-bootstrap-grid'
import DiscoverIcon from 'images/icons/discover.svg'
import StarterIcon from 'images/icons/starter.svg'
import BusinessIcon from 'images/icons/business.svg'
import EnterpriseIcon from 'images/icons/enterprise.svg'
import CheckIcon from 'images/icons/check.svg'
import CloseIcon from 'images/icons/close.svg'
import LetsTalkIcon from 'images/icons/forum.svg'
import { getOutlink } from '../../utils/analyticsUtils'
import LongTitle from '../Shared/FontStyles'

const TitleRow = styled(Row)`
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.primary.main};
`

const SwitchRow = styled(TitleRow)`
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
`

const SwitchBox = styled.div`
  padding: 8px;
`

const ColumnDirectionBox = styled.div`
  margin-top: 0px;
  justify-content: flex-start;
  flex-direction: column;
  justify-content: flex-start;
`

const StyledLabel = styled(Label)`
  color: ${({ theme }) => theme.palette.text.primary};
`

const PlanRow = styled(Row)`
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  ${media.md`
    flex-direction: row;
    align-items: stretch;
    justify-content: space-around;
  `}
  ${media.xl`
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
  `}
`

const DisclaimerRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  margin-top: -20px;
`

const Disclaimer = styled.p`
  text-align: center;
  padding: 0 20px;
  line-height: 1.5;
  font-size: ${({ theme }) => theme.fontSizes.body2};
`

const PlanContainer = styled.div`
  display: flex;
  flex-grow: 1;
  background-color: white;
  min-width: 230px;
  max-width: 280px;
  flex-basis: 100%;
  ${media.md`
    flex-basis: 40% !important;
    min-width: none;
    max-width: 310px;
  `}
  ${media.xl`
    flex-basis: 23% !important;
    min-width: 280px;
  `}
  margin: 24px 0;
  padding: 0px;
  border-radius: ${({ theme }) => theme.borders.radiusLg};
`

const PlanColumn = styled(Col)`
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: ${({ theme }) => theme.borders.radiusLg};
`

const PlanNameArea = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  position: relative;
  min-height: 200px;
  padding: 28px 28px 0px;
  color: white;
  border-top-left-radius: ${({ theme }) => theme.borders.radiusLg};
  border-top-right-radius: ${({ theme }) => theme.borders.radiusLg};
`

const PlanName = styled.h4`
  font-weight: 800;
  margin: 8px 0px;
`

const PlanText = styled.p`
  line-height: 1.2;
  font-family: Roboto;
  margin: 0;
  padding: 8px 0;
  color: ${({ theme }) => theme.palette.text.gray};
`

const SubDisclaimerText = styled.p`
  font-family: Exo;
  font-size: 16px;
  font-weight: 600;
  margin-top: -8px;
  padding: 0px;
  color: ${({ theme }) => theme.palette.primary.main};
`

const SubDisclaimerTextSwitch = styled(SubDisclaimerText)`
  padding: 0px 8px;
`

const TitleText = styled(PlanText)`
color: ${({ theme }) => theme.palette.secondary.gray};
`;

const StyledCheckIcon = styled(CheckIcon)`
  padding-right: 2px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  padding-right: 2px;
`;

const StyledLetsTalkIcon = styled(LetsTalkIcon)`
  height: 80px;
  width: 80px;
`;

const InfoArea = styled.div`
  padding: 24px;
`;

const PlanPriceArea = styled.div`
  padding: 24px 0 24px 0;
  height: 80px;
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.palette.primary.main};
`

const PriceTag = styled.h2`
  min-width: 100px;
  padding-right: 16px;
`;

const PlanTextArea = styled.div`
  padding: 16px 0;
  min-height: 80px;
`

const PlanFeaturesArea = styled.div``

const FeatureLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0;
`

const FeatureLineExcluded = styled(FeatureLine)`
  color: ${({ theme }) => theme.palette.primary.gray};
`

const Badge = styled(StyledTag)`
  position: absolute;
  top: -30px;
  left: 95%;
  transform: translateX(-100%);
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 2px 10px;
  font-size: 12px;
  min-width: 76px;
  text-transform: none;
`;

const StyledLinkButton = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  padding: ${({ size }) => (size === 'sm' ? '4px 4px' : '8px 28px')};
  min-width: ${({ size }) => (size === 'sm' ? '80px' : '104px')}};
  height: 28px;
  border-radius: 6px;
  text-decoration: none;
  transition: background-color 0.25s ease-in;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: white;
  border: 2px solid;
  border-color: ${({ theme }) => theme.palette.primary.main};
  p {
    transition: color 0.25s ease-in;
    color: ${({ theme }) => theme.palette.primary.main};
  }
  &:hover {
    color: white;
    background-color: ${({ theme }) => theme.palette.primary.main};
    p {
      color: white;
    }
  }
  &:active {
    color: white;
    background-color: ${({ theme }) => theme.palette.primary.main};
    p {
      color: white;
    }
  }
`;

const PricingSlice = () => {
  const [checked, setChecked] = useState(true);
  const theme = useTheme();
  const sliceContent = {
    titleClaim: [
      <span>Flexible plans</span>,
      <br />,
      <span>for growing or established businesses of all sizes</span>,
    ],
    annualSwitchText: 'Billed annually',
    annualSwitchTextDisclaimer: '2 months free',
    monthlySwitchText: 'Billed monthly',
    tag: 'PRICING',
    plans: [
      {
        priceName: 'Discover plan',
        priceCaption: 'Try Semplates free of charge Upgrade your plan anytime',
        price: '$0',
        annualPrice: '$0',
        button: {
          label: 'Get started now',
          to: `${getOutlink()}?plan=discover`,
        },
        text: "Free of charge forever - No credit card required",
        included: ['5 Templates', '1 Team Member', 'Template Starter Library'],
        excluded: ['Multiple AWS accounts'],
        Icon: DiscoverIcon,
      },
      {
        priceName: 'Starter plan',
        priceCaption: 'Ideal for solopreneurs, startups and small marketing teams',
        price: '$39',
        annualPrice: '$390',
        priceDisclaimer: 'billed monthly',
        priceDisclaimerAnnual: 'billed annually',
        priceDisclaimerDiscount: 'save $78',
        button: {
          label: 'Get started now',
          to: `${getOutlink()}?plan=starter`,
        },
        text: 'Tailor your plan as you like and add features as needed.',
        included: [
          '25 Templates*',
          '1 Team Member*',
          'Template Starter Library',
        ],
        excluded: [
          'Multiple AWS Accounts',
          'Reusable Content Blocks',
          'Personal Image Library',
          'Template Versioning',
        ],
        Icon: StarterIcon,
      },
      {
        priceName: 'Business plan',
        priceCaption:
          'Ideal for bigger teams, experts and corporate customers',
        price: '$89',
        annualPrice: '$890',
        priceDisclaimer: 'billed monthly',
        priceDisclaimerAnnual: 'billed annually',
        priceDisclaimerDiscount: 'save $178',
        button: {
          label: 'Get started now',
          highlight: true,
          to: `${getOutlink()}?plan=business`,
        },
        text:
          'Benefit from multiple AWS account & customer support',
        included: [
          '50 Templates*',
          '3 Team Members*',
          'Template Starter Library',
          'Multiple AWS Accounts*',
          'Reusable Content Blocks',
          'Personal Image Library',
          'Template Versioning',
          'Customer Support',
        ],
        Icon: BusinessIcon,
      },
      {
        priceName: 'Enterprise plan',
        priceCaption: 'Custom features and premium support to run your organisation',
        button: {
          label: 'Contact us',
          highlight: false,
          to: 'mailto:support@semplates.io?subject=Semplates%20Enterprise%20Plan',
        },
        showChatIcon: true,
        text:
          'Shoot us an email and we find the optimal solution to your needs.',
        included: [
          'Unlimited Templates',
          'Unlimited Members',
          'Template Starter Library',
          'Unlimited AWS Accounts',
          'Reusable Content Blocks',
          'Personal Image Library',
          'Template Versioning',
          'Premium Customer Support',
          'Custom Features and Integrations',
        ],
        Icon: EnterpriseIcon,
      },
    ],
    disclaimer:
      '*Templates / Users / AWS accounts can be flexibly added for $0.8 / $10 / $20 per month.',
  }

  const PricingPlan = ({ description, displayAnnual = true }) => (
    <PlanContainer>
      <PlanColumn>
        <PlanNameArea>
          {description.button.highlight === true && <Badge>Most popular</Badge>}
          <description.Icon />
          <PlanName>{description.priceName}</PlanName>
          <TitleText>{description.priceCaption}</TitleText>
        </PlanNameArea>
        <InfoArea>
          <PlanPriceArea>
            {description.price && description.annualPrice && <PriceTag>{displayAnnual ? description.annualPrice : description.price}</PriceTag>}

            <ColumnDirectionBox>
            {description.priceDisclaimer && (
              <>
                <PlanText>{displayAnnual ? description.priceDisclaimerAnnual : description.priceDisclaimer}</PlanText>
                <SubDisclaimerText>{displayAnnual && description.priceDisclaimerDiscount}</SubDisclaimerText>
              </>
            )}
            </ColumnDirectionBox>
            {description.showChatIcon && <StyledLetsTalkIcon />}
          </PlanPriceArea>
          <PlanTextArea>
            <PlanText>{description.text}</PlanText>
          </PlanTextArea>
          {description.button.highlight === true ?
            <LinkButton
            to={description.button.to}
            label={description.button.label}
        />
          :
            <StyledLinkButton
            href={description.button.to}
            target="_blank"
            rel="noopener noreferrer"
            ><Label>{description.button.label}</Label></StyledLinkButton>
          }
          <PlanFeaturesArea>
            {description.included &&
              description.included.map((featureName, index) => (
                <FeatureLine key={index}>
                  <StyledCheckIcon />
                  {featureName}
                </FeatureLine>
              ))}
            {description.excluded &&
              description.excluded.map((featureName, index) => (
                <FeatureLineExcluded key={index}>
                  <StyledCloseIcon />
                  {featureName}
                </FeatureLineExcluded>
              ))}
          </PlanFeaturesArea>
        </InfoArea>
      </PlanColumn>
    </PlanContainer>
  )

  const handleChange = (val) => {
    setChecked(val);
  };

  return (
    <Section>
      <TitleRow>
        <StyledTag>{sliceContent.tag}</StyledTag>
      </TitleRow>
      <TitleRow>
        <LongTitle>{sliceContent.titleClaim}</LongTitle>
      </TitleRow>
      <SwitchRow>
        <StyledLabel>
          {sliceContent.monthlySwitchText}
        </StyledLabel>
        <SwitchBox>
          <Switch 
            onChange={handleChange} 
            checked={checked} 
            handleDiameter={18} 
            checkedIcon={false} 
            uncheckedIcon={false} 
            onHandleColor={theme.palette.secondary.main} 
            offHandleColor={theme.palette.primary.main} 
            offColor="#FFFFFF"
            onColor={theme.palette.primary.main}
          />
        </SwitchBox>
        <ColumnDirectionBox>
          <StyledLabel>
            {sliceContent.annualSwitchText}
          </StyledLabel>
          <SubDisclaimerTextSwitch>{sliceContent.annualSwitchTextDisclaimer}</SubDisclaimerTextSwitch>
        </ColumnDirectionBox>
      </SwitchRow>
      <PlanRow>
        {sliceContent.plans.map((plan, idx) => (
          <PricingPlan description={plan} key={idx} displayAnnual={checked} />
        ))}
      </PlanRow>

      <DisclaimerRow>
        <Disclaimer>{sliceContent.disclaimer}</Disclaimer>
      </DisclaimerRow>
    </Section>
  )
}

export default PricingSlice
