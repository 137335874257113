import React from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import { Col, Row, Section } from '../Layout'
import { FadeOnSlide, LinkButton } from '../Shared'

const StyledRow = styled(Row)`
  align-items: center;
  flex-direction: column;

  ${media.lg`
    flex-direction: row;
    justify-content: space-between;
  `}
`

const TextCol = styled(Col)`
  justify-content: flex-start;
  margin: 24px 0;
  ${media.lg`
    margin: 32px 0px;
  `}
`

const ImageCol = styled(TextCol)`
  align-items: center;
`
const StyledTitle = styled.h2`
  color: ${({ theme }) => theme.palette.primary.main};
`

const StyledImage = styled(GatsbyImage)`
  position: relative;
  border-radius: 8px;
  margin-top: 8px;
  right: 24px;
  width: 100%;
`

const ButtonCaption = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

const PricingTeaserSlice = () => {
  const sliceContent = {
    title: 'Find your perfect price plan',
    text: 'We offer flexible price plans that can be up- and downgraded according to your needs.',
    buttonCaption: 'Our start plan is free. Forever.',
    button: { label: 'See price plans', to: '/pricing' },
    imageAlt:
      'We offer flexible price plans that can be up- and downgraded according to your needs.',
  }

  const data = useStaticQuery(graphql`
    query pricingTeaserQuery {
      ipad: file(relativePath: { eq: "ipad.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 65
            width: 900
            placeholder: NONE
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  const image = getImage(data.ipad)
  return (
    <Section>
      <StyledRow>
        <FadeOnSlide>
          <TextCol>
            <StyledTitle>{sliceContent.title}</StyledTitle>
            <p>{sliceContent.text}</p>
            <ButtonCaption>{sliceContent.buttonCaption}</ButtonCaption>
            <LinkButton
              to={sliceContent.button.to}
              label={sliceContent.button.label}
            />
          </TextCol>
        </FadeOnSlide>
        <ImageCol>
          <StyledImage image={image} alt={sliceContent.imageAlt} />
        </ImageCol>
      </StyledRow>
    </Section>
  )
}

export default PricingTeaserSlice
