import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { Api, Faq, Start } from '../../images/documentation'
import { Row } from '../Layout'

const CardContainer = styled.div`
  border-radius: ${({ theme }) => theme.borders.radius};
  background-color: ${({ theme }) => theme.palette.background.main};
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 12px 24px;
  margin: 0 0 48px 0;
  width: 330px;
  transition: margin 0.25s ease;
  transition-delay: 0.05s;
  text-decoration: none !important;
  &:hover {
    margin: -16px 0 64px 0;
    box-shadow: ${({ theme }) => theme.shadows.main};
  }

  ${media.lg`
    margin: 0 48px 48px 0;
    &:hover {
    margin: -16px 48px 64px 0;
    box-shadow: ${({ theme }) => theme.shadows.main};
  }
  `}
`

const CardLink = styled(Link)`
  text-decoration: none;
`

const TitleRow = styled(Row)`
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  height: 120px;
`

const IconRow = styled(TitleRow)`
  align-items: center;
  margin-top: 32px;
`

const TextRow = styled(TitleRow)`
  align-items: flex-end;
`

const Subtitle = styled.h2`
  color: ${({ theme }) => theme.palette.primary.dark};
  font-size: 30px;
`

const Icon = ({ categorySlug }) => {
  switch (categorySlug) {
    case 'setup-guide':
      return <Start />
    case 'template-design':
      return <Api />
    case 'faq':
    default:
      return <Faq />
  }
}

const DocumentationCard = ({ categoryName, categorySlug }) => {
  const data = useStaticQuery(graphql`
    query allDocumentationPages {
      allContentfulDocumentationPage(sort: { fields: sortOrder, order: ASC }) {
        edges {
          node {
            id
            documentationCategory {
              slug
            }
            sortOrder
            subTitle
            title
            content {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `)
  const articleCount = data.allContentfulDocumentationPage.edges.filter(
    edge => edge.node.documentationCategory.slug === categorySlug
  ).length
  let countDisclaimer =
    articleCount !== 1 ? `${articleCount} articles` : '1 article'
  if (categorySlug === 'faq') {
    countDisclaimer =
      articleCount !== 1 ? `${articleCount} questions` : '1 question'
  }
  return (
    <CardLink
      to={categorySlug ? `/documentation/${categorySlug}` : '/documentation'}
    >
      <CardContainer>
        <TitleRow>
          <h2>{categoryName}</h2>
        </TitleRow>
        <IconRow>
          <Icon categorySlug={categorySlug} />
        </IconRow>
        <TextRow>
          <Subtitle>{countDisclaimer}</Subtitle>
        </TextRow>
      </CardContainer>
    </CardLink>
  )
}

export default DocumentationCard
