import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { media, Col, Row } from 'styled-bootstrap-grid'
import { Section } from '../Layout'
import CaseStudyCard from '../CaseStudies/CaseStudyCard'
import useWindowSize from '../../hooks/useGatsbyWindowSize'
import { SliderDot, StyledSlider } from '../Shared/Slider'

const CardContainer = styled.div`
  display: flex !important;
  justify-content: center;
  margin: auto;
  ${media.lg`  
    height: 560px;
    align-items: end;
    width: min-content;
    transition: all 1s ease;
  `}
  @media (max-width: 420px) {
    justify-content: left;
  }
`

const SliderContainer = styled.div`
  ${media.lg`
    position: relative;
  `}
`

const DotContainer = styled.div`
  position: relative;
  top: 40px;
  ${media.lg`
    right: 350px;
    top: 40px;
  `}
`

const CenteredRow = styled(Row)`
  align-items: center;
  margin-bottom: 60px;
`

const CaseStudiesMainPageSlice = () => {
  const sliderColRef = useRef(null)
  const [cardIndex, setActiveCard] = useState(0)
  const { width } = useWindowSize()
  const data = useStaticQuery(graphql`
    query CaseStudiesCardQueryMain {
      allContentfulCaseStudy {
        nodes {
          heroImage {
            gatsbyImageData(placeholder: NONE)
          }
          shortDescription
          title
          slug
        }
      }
    }
  `)

  const caseStudies = data.allContentfulCaseStudy.nodes
  const sliceContent = {
    heading: 'Real Life Voices from our Clients',
    description:
      "Discover global success stories and learn how Semplates empowers customers across industries with its advanced AWS SES template manager, driving efficiency and team collaboration.",
  }

  const cardsPerSlide = sliderColRef.current?.offsetWidth / 350

  const sliderSettings = {
    customPaging() {
      return (
        <DotContainer>
          <SliderDot />
        </DotContainer>
      )
    },
    dots: true,
    arrows: width > 560 && true,
    dotsClass: 'slick-dots',
    className: 'slider variable-width',
    focusOnSelect: true,
    infinite: true,
    autoplaySpeed: 4000,
    speed: 1000,
    autoplay: caseStudies.length > 3,
    slidesToScroll: 1,
    prevArrow: null,
    afterChange: current => setActiveCard(current),
  }

  return (
    <Section bgStyle="dark">
      <CenteredRow>
        <Col col xl="3">
          <h2>{sliceContent.heading}</h2>
          <p>{sliceContent.description}</p>
        </Col>
        <Col col xl="8" ref={sliderColRef}>
          <SliderContainer>
            <StyledSlider
              {...{
                ...sliderSettings,
                // eslint-disable-next-line max-len
                slidesToShow: Math.floor(cardsPerSlide),
              }}
            >
              {caseStudies.map((caseStudy, idx) => (
                <CardContainer key={idx} onClick={() => setActiveCard(idx)}>
                  <CaseStudyCard
                    post={caseStudy}
                    active={idx === cardIndex}
                    slideItem
                  />
                </CardContainer>
              ))}
            </StyledSlider>
          </SliderContainer>
        </Col>
      </CenteredRow>
    </Section>
  )
}

export default CaseStudiesMainPageSlice
