import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Row, Section } from 'components/Layout'
import { LinkButton } from 'components/Shared'
import { media } from 'styled-bootstrap-grid'
import Hero from '../../videos/hero.mp4'
import { SelfHostedVideo } from '../Landing'
import useWindowSize from '../../hooks/useGatsbyWindowSize'
import { getOutlink } from '../../utils/analyticsUtils'

export const ButtonRow = styled(Row)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  @media (max-width: 420px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const VideoContainer = styled.div`
  display: grid;
  align-content: center;
`

const StyledSubtitle = styled.h3`
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-family: Exo;
  font-size: 20px;
  padding: 20px 0;
  line-height: 28px;
`

export const StyledAWSLogo = styled.img`
  margin-left: 40px;
  margin-bottom: 18px;
  width: 110px;
  @media (max-width: 420px) {
    margin-left: 0px;
    margin-top: 12px;
  }
`

const ContentContainer = styled.div`
  width: 80%;
`

const Container = styled.div`
  display: grid;
  width: 100%;
  ${media.lg`
    grid-template-columns: 1fr 1fr;
  `}
`

const TemplatesHeroSlice = ({ title, subtitle, buttonLabel }) => {
  const { width } = useWindowSize()
  const theme = useTheme()
  const sliceContent = {
    imageAlt:
      'The Semplates AWS SES template editor makes it easy to create responsive, personalized and branded mail templates via drag and drop.',
    buttonLabel: buttonLabel ?? 'Get started today!',
    outLink: getOutlink(),
  }
  const HeroContent = () => (
    <ContentContainer>
      {title && <h1>{title}</h1>}
      {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
      <ButtonRow>
        <LinkButton
          size={width < theme.breakpoints.sm ? 'sm' : ''}
          label={sliceContent.buttonLabel}
          to={sliceContent.outLink}
        />
        <a href="https://aws.amazon.com/what-is-cloud-computing">
          <StyledAWSLogo
            src="https://d0.awsstatic.com/logos/powered-by-aws-white.png"
            alt="Powered by AWS Cloud Computing"
            target="_blank"
          />
        </a>
      </ButtonRow>
    </ContentContainer>
  )
  return (
    <Section bgStyle="dark" showWave>
      <Container>
        <HeroContent />
        <VideoContainer>
          <SelfHostedVideo src={Hero} />
        </VideoContainer>
      </Container>
    </Section>
  )
}

export default TemplatesHeroSlice
