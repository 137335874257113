import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { media } from 'styled-bootstrap-grid'
import Logo from 'images/logo-ab-testing.svg'
import SocialLinks from './SocialLinks'

const StyledFooter = styled.footer`
  margin: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Container = styled.div`
  max-width: 1350px;
  margin: 48px 16px 16px 16px;
  width: calc(100% - 48px);
  color: white;
  margin: '24px 32px 24px 32px':
`

const StyledLogo = styled(Logo)`
  width: 150px;
`

const FooterText = styled.div`
  ${media.md`
    display: grid;
    gap: 20px;
    grid-template-columns: 3fr 1fr 1fr;
  `}
`

const FooterContactLinkText = styled.span`
  color: white;
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
`

const FooterCopyRight = styled.span`
  font-family:  ${({ theme }) => theme.fontFamilies.secondary};
  font-size: 14px;
  font-weight: 300;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 12px;
  ${media.lg`
    display: flex;
  `};
`

const FooterLink = styled(Link)`
  text-decoration: none;
`

const LogoLink = styled(Link)`
  position: relative;
  text-decoration: none;
  ${media.xl`
    padding-right: 60px;
  `}
`

const YellowText = styled.p`
  font-family:  ${({ theme }) => theme.fontFamilies.secondary};
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-top: 8px;
  margin-bottom: 10px;
`
  
const Subtitle = styled.p`
  margin-top: 0;
  font-family:  ${({ theme }) => theme.fontFamilies.secondary};
  font-size:  ${({ theme }) => theme.fontSizes.body2} !important;
  color: ${({ theme }) => theme.palette.background.gray};
`

const sliceContent = {
  footerHeader1: 'Support',
  footerHeader2: 'Legal',
  subtitle:
    'Create, edit, send email templates on AWS SES without a single line of code.',
  footerLinks: [
    { to: '/blog', label: 'Blog' },
    { to: '/documentation', label: 'Documentation' },
    { to: 'mailto:support@semplates.io', label: 'Contact us' },
    { to: '/data-privacy', label: 'Data Privacy' },
    { to: '/disclaimer', label: 'Disclaimer' },
  ],
}

const Footer = () => (
  <StyledFooter>
    <Container>
      <FooterText>
        <div>
          <LogoLink to="/" arial-label="Go to Semplates.io' main page">
            <StyledLogo alt="Semplates logo"/>
          </LogoLink>
          <Subtitle>{sliceContent.subtitle}</Subtitle>
          <FooterCopyRight>
        <SocialLinks />
        ©
        Semplates
        &nbsp;
        {new Date().getFullYear()}
        &nbsp;
        &nbsp;
        &nbsp;
        <FooterLink to={sliceContent.footerLinks[2].to}>
            <FooterContactLinkText>{sliceContent.footerLinks[2].label}</FooterContactLinkText>
        </FooterLink>
      </FooterCopyRight>
        </div>
        <div>
          <YellowText>{sliceContent.footerHeader1}</YellowText>
          <FooterLink to={sliceContent.footerLinks[0].to}>
            <Subtitle>{sliceContent.footerLinks[0].label}</Subtitle>
          </FooterLink>
          <FooterLink to={sliceContent.footerLinks[1].to}>
            <Subtitle>{sliceContent.footerLinks[1].label}</Subtitle>
          </FooterLink>
        </div>
        <div>
          <YellowText>{sliceContent.footerHeader2}</YellowText>
          <FooterLink to={sliceContent.footerLinks[2].to}>
            <Subtitle>{sliceContent.footerLinks[2].label}</Subtitle>
          </FooterLink>
          <FooterLink to={sliceContent.footerLinks[3].to}>
            <Subtitle>{sliceContent.footerLinks[3].label}</Subtitle>
          </FooterLink>
        </div>
      </FooterText>
    </Container>
  </StyledFooter>
)

export default Footer
