import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery} from 'gatsby'
import { media } from 'styled-bootstrap-grid'
import { Section } from 'components/Layout'
import { FeatureCard, TabBarCard } from 'components/Features'
import InternalApprovalLink from 'images/icons/internal-approval-link.svg'
import CloudStored from 'images/icons/cloud-stored.svg'
import TrackInternalHistory from 'images/icons/track-internal-history.svg'

const Container = styled.div`
  scroll-margin-top: 100px;
`
const Tag = styled.p`
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: ${({ theme }) => theme.fontFamilies.main};
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: ${({ theme }) => theme.fontSizes.body2};
`

const Title = styled.h2`
  margin-top: 40px;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

const GridContainer = styled.div`
  margin: 48px 0px;
  display: grid;
  grid-template-rows: 1fr;
   grid-template-areas: 
   "main"
   "left"
   "center"
   "right"
  ;
  text-align: left;
  grid-gap: 32px;
  transition: all 0.25s ease-in-out;
  min-height: 1200px;
  height: 300vh;
  ${media.lg`
    margin: 72px 0px 0px 0px;
    min-height: 1500px;
    height: 180vh;
    grid-template-rows: 0.25fr 0.25fr 0.25fr 0.25fr;
    grid-template-areas:
      "main main main main"
      "main main main main"
      "left center center right"
    ;
  `}
  ${media.xxl`
    height: 110vh;
  `}
`;

const HeroContent = () => {
  const data = useStaticQuery(graphql`
      query CollaborationImages {
        trackHistory: file(relativePath: { eq: "track-history.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 90
              placeholder: NONE
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        enhancedCollaboration: file(relativePath: { eq: "enhanced-collaboration.mp4" }) {
          publicURL
        }
        multiStageModel: file(relativePath: { eq: "multi-stage-model.mp4" }) {
          publicURL
        }
        iam: file(relativePath: { eq: "iam.mp4" }) {
          publicURL
        }
      }
  `);

  const sliceContent = {
    titleClaim: [
      <span>Invites team members and let design, sales and</span>,
      <br />,
      <span>product experts collaborate with Semplates</span>,
    ],
    tagText: 'COLLABORATION',
    cardContent: [
      {
        title: 'Cloud stored Templates for more reliability',
        text: "Don't loose any progress made on your template and access them from anywhere",
        imageData: undefined,
        Icon: CloudStored,
        gridArea: 'left'
      },
      {
        title: 'Track internal template history',
        text: "View and track your templates' change and publishing history. Restore as needed.",
        imageData: data.trackHistory,
        Icon: TrackInternalHistory,
        gridArea: 'center'
      },
      {
        title: 'Internal approval link',
        text: 'Share and send emails to your colleagues for them to test and provide valuable feedback',      
        imageData: undefined,
        Icon: InternalApprovalLink,
        gridArea: 'right',
        comingSoon: true
      },
    ],
    tabCardContent: [
      {
        "title": "📨 Multi-stage publishing model",
        "text": "Effortlessly manage and streamline your publication process with our multi-stage publishing model. This feature allows you to create, review, and publish content in distinct stages, ensuring quality and consistency. Whether it's drafting, reviewing, or final approval, keep every step organized and transparent.",
        "videoData": data.multiStageModel.publicURL
      },
      {
        "title": "🤝 Enhanced collaboration across teams",
        "text": "Invite any team members to your workspace, from design and sales to product experts, and give them respective level access. Level up your collaborative potential without losing any control over changes made by your team. Foster a dynamic and cohesive work environment where every member can contribute effectively.",
        "videoData": data.enhancedCollaboration.publicURL
      },     
      {
        "title": "🔐 Custom roles, user groups and permissions",
        "text": "Define and control access with precision using user groups and permission sets. Assign specific permissions to users based on their roles, ensuring that everyone has the right level of access to perform their tasks efficiently. Enhance security and maintain clear boundaries within your account.",
        "videoData": data.iam.publicURL
      },
    ]
  }

  return (
      <Container id="collaboration">
        <Tag>{sliceContent.tagText}</Tag>
        <Title>{sliceContent.titleClaim}</Title>
        {sliceContent.subtitle}
        <GridContainer>
          <TabBarCard gridArea="main" tabBarContent={sliceContent.tabCardContent}/>
          {sliceContent.cardContent.map((card, idx) => (
            <FeatureCard key={idx} {...card} />
          ))}
        </GridContainer>
      </Container>
  )
};

const FeaturesSliceCollaboration = () => (
    <Section bgStyle="dark">
      <HeroContent />
    </Section>
  )

export default FeaturesSliceCollaboration
