import React from 'react'
import styled from 'styled-components'
import { Col, media } from 'styled-bootstrap-grid'
import { graphql, useStaticQuery } from 'gatsby'
import { Row } from '../Layout'
import { StyledSlider } from '../Shared'

const StyledSection = styled.div`
  margin: 0;
  width: 100%;
  padding: 144px 0 8px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 20px;
  background: ${({ theme }) => theme.palette.background.light};
  ${media.lg`
    padding-top: 500px;
  `}
`

const GridContainer = styled.div`
  width: calc((100% - 56px)* 0.9);
  max-width: calc(1320px * 0.9);
  margin-right: 32px;
  @media (max-width: 600px) {
    margin-right: 16px;
    width: calc(100% - 56px);
  }
`

const StyledRow = styled(Row)`
  flex-direction: column;
  width: 100%;
  ${media.lg`
    flex-direction: row;
    align-items: flex-end;
    margin: 0 8px;
  `}
`

const BrandsDisclaimer = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin: 0;
  color: rgba(111, 117, 96, 0.7);
  height: 40px;
  ${media.lg`
  height: 30px;
  `}
  `
  
  const LogoContainer = styled.div`
  margin: 0 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px; 
  ${media.lg`
    height: 60px;
  `}
`

const StyledLogo = styled.img`
  height: 60px;
  width: auto;
  max-width: 100px;
  cursor: pointer;
  display: block;

  ${media.lg`
    max-width: 200px;
  `}
`

const OverflowHidden = styled.div`
  width: 100%;
  overflow: hidden;
`

const ClientsSlice = ({ autoPlay }) => {
  const shouldCarouselDoAutoPlay = autoPlay ?? true
  const data = useStaticQuery(graphql`
    query ClientLogoQuery {
      allContentfulCustomerLogo(
        sort: { fields: logo___description, order: ASC }
      ) {
        nodes {
          url
          logo {
            gatsbyImageData(quality: 80, layout: FULL_WIDTH, placeholder: NONE)
            description
            url
          }
        }
      }
    }
  `)

  const companyLogos = data.allContentfulCustomerLogo.nodes
  const sliceContent = {
    brandsDisclaimer: '500+ organisations trust Semplates',
  }

  const sliderSettings = {
    dots: false,
    infinite: true,
    autoplaySpeed: 500,
    speed: 1800,
    autoplay: shouldCarouselDoAutoPlay,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
  }

  return (
    <OverflowHidden>

      <StyledSection>
        <GridContainer>
          <StyledRow>
            <Col col xl="4" lg="4" md="4" sm="12" xs="12">
              <Row>
                <BrandsDisclaimer>
                  {sliceContent.brandsDisclaimer}
                </BrandsDisclaimer>
              </Row>
            </Col>
            <Col col xl="8" lg="8" md="8" sm="12" xs="12">
              <StyledSlider style={{ padding: 0 }} {...sliderSettings}>
                {companyLogos.map((customer, index) => (
                  <LogoContainer key={index}>
                    <a target="_blank" href={customer.url} rel="noreferrer">
                      <StyledLogo
                        src={customer.logo.url}
                        alt={customer.logo.description}
                      />
                    </a>
                  </LogoContainer>
                ))}
              </StyledSlider>
            </Col>
          </StyledRow>
        </GridContainer>
      </StyledSection>
    </OverflowHidden>
  )
}

export default ClientsSlice
