import React from 'react'
import styled, { useTheme } from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { media } from 'styled-bootstrap-grid'
import { Section } from '../Layout'
import CaseStudySlider from '../CaseStudies/CaseStudySlider';
import { DiscoverButton } from '../Shared'

const SubTitle = styled.h3`
`

const SliceHeader = styled.div`
  display: grid;
  gap: 20px;
  ${media.md`
    grid-template-columns: 1.5fr 1fr;
  `}
`

const Tag = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: ${({ theme }) => theme.fontFamilies.main};
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: ${({ theme }) => theme.fontSizes.body2};
`

const Title = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeights.titleBold};
`

const YellowText = styled.span`
  color: ${({ theme }) => theme.palette.secondary.main};
`

const SuccessSlice = () => {
  const theme = useTheme()
  const data = useStaticQuery(graphql`
    query CaseStudiesCardQuery {
      allContentfulCaseStudy {
        nodes {
          heroImage {
            gatsbyImageData(placeholder: NONE)
          }
          shortDescription
          title
          slug
        }
      }
    }
  `)

  const caseStudies = data.allContentfulCaseStudy.nodes
  const sliceContent = {
    title: [
      <Title>
        Get inspired and see how we empowered our clients, and how{' '}
        <YellowText>we could help you</YellowText>
      </Title>,
    ],
    subtitle:
      "Discover global success stories and learn how Semplates empowers customers across industries with its advanced AWS SES template manager, driving efficiency and team collaboration.",
    button: { label: 'Discover more success stories', to: '/case-studies' },
  }

  return (
    <Section bgStyle="dark">
      <Tag>CUSTOMER SUCCESS STORIES</Tag>
      <SliceHeader>
        <div>
          {sliceContent.title}
          <SubTitle>{sliceContent.subtitle}</SubTitle>
        </div>
        <DiscoverButton
          color={theme.palette.secondary.main}
          to={sliceContent.button.to}
        >
          {sliceContent.button.label}
        </DiscoverButton>
      </SliceHeader>
      <CaseStudySlider caseStudies={caseStudies} />
    </Section>
  )
}

export default SuccessSlice
