import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import StyledTag from 'components/Shared/StyledTag';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Area = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: white;
  border-radius: ${({ theme }) => theme.borders.radiusLg};
  min-height: 250px;
  height: 100%;
  grid-area: ${({ gridArea }) => gridArea};
  ${media.lg`
    flex-direction: ${({ imagePosition }) => imagePosition === 'right' ? 'row' : 'column'};
  `}
`;

const TextSection = styled.div`
  position: relative;
  padding: 24px;
  color: ${({ theme }) => theme.palette.primary.main};
  max-width: 100%;
  height: ${({ imageAvailable, imagePosition }) => imageAvailable && imagePosition === 'bottom' ? '50%' : '100%'};
  ${media.lg`
    padding: '24px';
    max-width: ${({ imageAvailable, imagePosition }) => imageAvailable && imagePosition === 'right' ? '45%' : '100%'};
  `}
`;

const StyledTitle = styled.h3`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 18px;
`;

const StyledText = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const ImageSection = styled.div`
  position: relative; // Make this a positioning context for absolute children
  flex-basis: 60%;
  display: none;
  border-bottom-right-radius: ${({ theme }) => theme.borders.radiusLg};
  border-bottom-left-radius: ${({ theme }) => theme.borders.radiusLg};
  ${media.lg`
    display: ${({ image }) => image ? 'block' : 'none'};
    border-bottom-left-radius: ${({ theme, imagePosition }) => imagePosition === 'right' ? '0px' : theme.borders.radiusLg};
    border-top-right-radius: ${({ theme, imagePosition }) => imagePosition === 'right' ? theme.borders.radiusLg : '0px'};
    width: ${({ imagePosition }) => imagePosition === 'right' ? '55%' : '100%'};
    height: ${({ imagePosition }) => imagePosition === 'right' ? '100%' : '55%'};
  `};
  padding-left: ${({ imagePosition }) => imagePosition === 'bottom' ? '24px' : '0px'};
`;

const StyledImage = styled(GatsbyImage)`
  width: 85%;
  height: auto;
  max-height: 100%;
  margin: 16px 8px 16px;
  object-fit: contain;
  border-bottom-right-radius: ${({ theme }) => theme.borders.radiusLg};
  display: block;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  transform: translateY(-50%);
`;

const Badge = styled(StyledTag)`
  position: absolute;
  top: 12px;
  right: 24px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 6px 12px; // Adjusted padding
  font-size: 12px;
  // position the badge relative and make it stick to the top right corner of the parent
  min-width: 84px; // Ensure minimum width for the text
`;

const IconContainer = styled.div`
  margin-bottom: 16px;
`;

const FeatureCard = ({ title, text, Icon, imageData, gridArea, imagePosition = 'right', comingSoon = false }) => (
    <>
      <Area gridArea={gridArea} imagePosition={imagePosition}>
        <TextSection imageAvailable={imageData} imagePosition={imagePosition}>
          {Icon && 
            <IconContainer> 
              <Icon/>
            </IconContainer>
          }
          <StyledTitle>{title || "Title is missing"}</StyledTitle>
          <StyledText>{text || "Text is missing"}</StyledText>
        { comingSoon && <Badge>Coming soon</Badge>}
        </TextSection>
        <ImageSection image={!!imageData} imagePosition={imagePosition}>
          <StyledImage image={getImage(imageData)} alt={title}/>
        </ImageSection>
      </Area>
    </>
  );

export default FeatureCard;
