import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import { helmetJsonLdProp } from 'react-schemaorg'
import { getImage } from 'gatsby-plugin-image'

const Seo = ({
  description,
  lang,
  meta,
  title,
  pathname,
  image: heroImage,
  keywords,
  faq = [],
  breadcrumbList = [],
  script = [],
  organizationLogo,
}) => {
  const { site, metaImage, darkLogo } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            seoTitle
            description
            author
            keywords
            siteUrl
          }
        }
        metaImage: file(relativePath: { eq: "social_sharing_card.png" }) {
          childImageSharp {
            resize(width: 1200) {
              src
              width
              height
            }
          }
        }
        darkLogo: file(relativePath: { eq: "dark_logo.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
          }
        }
      }
    `
  )
  const metaDescription = description || site.siteMetadata.description
  const image =
    heroImage?.gatsbyImageData?.images?.fallback?.src ??
    heroImage?.gatsbyImageData?.images?.sources[0] ??
    `${metaImage.childImageSharp.resize.src}`
  const imageHeight = heroImage
    ? heroImage.gatsbyImageData.height
    : metaImage.childImageSharp.resize.height
  const imageWidth = heroImage
    ? heroImage.gatsbyImageData.width
    : metaImage.childImageSharp.resize.width

  const { seoTitle } = site.siteMetadata
  const canonical = pathname
    ? `${site.siteMetadata.siteUrl}/${pathname}`
    : site.siteMetadata.siteUrl
  const helmetJsonLdPropArray = []
  if (breadcrumbList?.length) {
    helmetJsonLdPropArray.push({
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbList.map(({ name, path, position }) => ({
        '@type': 'ListItem',
        position,
        name,
        item: `https://semplates.io/${path}`,
      })),
    })
  }

  if (faq?.length) {
    helmetJsonLdPropArray.push({
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: [
        faq.map(({ question, answer }) => ({
          '@type': 'Question',
          name: question,
          acceptedAnswer: {
            '@type': 'Answer',
            text: answer,
          },
        })),
      ],
    })
  }

  if (script?.length) {
    script.forEach(element => {
      helmetJsonLdPropArray.push({
        ...element,
        '@context': 'https://schema.org',
      })
    })
  }

  if (organizationLogo) {
    const logo = getImage(darkLogo)

    helmetJsonLdPropArray.push({
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: 'Semplates',
      logo: `https://semplates.io${
        logo?.images?.fallback?.src ?? logo?.images?.sources[0]
      }`,
      url: 'https://semplates.io/',
    })
  }

  const jsonLd = helmetJsonLdProp(helmetJsonLdPropArray)
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={
        canonical
          ? [
              {
                rel: 'canonical',
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: keywords
            ? keywords.join(',')
            : site.siteMetadata.keywords.join(','),
        },
        {
          property: 'og:title',
          content: title ?? seoTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:url',
          content: canonical,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'og:site_name',
          content: 'Semplates',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title ?? seoTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ]
        .concat(
          image
            ? [
                {
                  property: 'og:image',
                  content: image,
                },
                {
                  property: 'og:image:width',
                  content: imageWidth,
                },
                {
                  property: 'og:image:height',
                  content: imageHeight,
                },
                {
                  name: 'twitter:card',
                  content: 'summary_large_image',
                },
              ]
            : [
                {
                  name: 'twitter:card',
                  content: 'summary',
                },
              ]
        )
        .concat(meta)}
      script={[jsonLd]}
    />
  )
}

Seo.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
  keywords: undefined,
  pathname: undefined,
}
Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  meta: PropTypes.arrayOf(PropTypes.any),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  pathname: PropTypes.string,
}
export default Seo
