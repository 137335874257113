import React from 'react'
import styled from 'styled-components'
import { Section, Row } from 'components/Layout'
import { TemplateCarousel } from 'components/Shared'

const PaddedRow = styled(Row)`
  padding: 0 0 32px 0;
`

const StartersSlideSlice = ({ sliceTitle }) => {
  const sliceContent = {
    sliceTitle: sliceTitle || 'Email templates ready for your branding',
  }

  return (
    <Section bgStyle="dark">
      <Row alignItems="center" justifyContent="center">
        <h2>{sliceContent.sliceTitle}</h2>
      </Row>
      <PaddedRow>
        <TemplateCarousel />
      </PaddedRow>
    </Section>
  )
}

export default StartersSlideSlice
