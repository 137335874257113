import React from 'react'
import styled from 'styled-components'

const StyledVideo = styled.video`
  box-shadow: ${({ theme }) => theme.shadows.main};
  border-radius: ${({ theme }) => theme.borders.radius};
`

const VideoPlayer = ({
  src,
  width = '100%',
  height = 'auto',
  controls = false,
  autoPlay = true,
}) => (
  // eslint-disable-next-line jsx-a11y/media-has-caption
  <StyledVideo
    width={width}
    height={height}
    autoPlay={autoPlay}
    controls={controls}
    loop={autoPlay}
    muted
    playsinline
  >
    <source src={src} type="video/mp4" />
  </StyledVideo>
)

export default VideoPlayer
