import React from 'react'
import styled from 'styled-components'
import { Row, Section } from 'components/Layout'
import { LinkButton } from 'components/Shared'
import { media } from 'styled-bootstrap-grid'
import { getOutlink } from '../../utils/analyticsUtils'

const StyledAWSLogo = styled.img`
  width: 144px;
  margin-top: 32px;
  ${media.md`
    margin-top: 16px;
    margin-left: 40px;
  `}
`

const StyledSubTitle = styled.p`
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-size: 20px;
  text-align: center;
  ${media.md`
  padding: 0px;
  `}
  padding: 16px 8px;
`

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.background.dark};
  border-radius: 24px;
  color: white;
  ${media.sm`
    padding: 40px 60px;
  `}
  padding: 40px 8px;
`

const YellowText = styled.span`
  color: ${({ theme }) => theme.palette.secondary.main};
`

const Title = styled.h2`
  max-width: 550px;
  padding: 16px 8px;
  text-align: center;
  margin: auto;
  font-weight: ${({ theme }) => theme.fontWeights.titleBold};
`

const AWSLink = styled.a`
  display: grid;
  justify-content: center;
`

const Buttons = styled.div`
  margin: 16px 8px;
  ${media.md`
    display:flex;
    justify-content: center;
  `}
`

const Br = styled.br`
  display: none;
  ${media.sm`
    display: block;
  `}
`

const SignupSlice = ({ sliceTitle }) => {
  const sliceContent = {
    sliceTitle: sliceTitle ?? [
      <Title>
        Start sending&nbsp;
        <YellowText>great emails</YellowText>
        <Br /> with <YellowText>Amazon SES,</YellowText>&nbsp;hassle free!
      </Title>,
    ],
    subTitle: (
      <StyledSubTitle>
        Our discover plan is free forever. No credit card required. <br /> Need
        more functionalities? You can upgrade anytime.
      </StyledSubTitle>
    ),
    button: {
      to: getOutlink(),
      label: 'Get started now for free',
    },
  }
  return (
    <Section>
      <Container>
        {sliceContent.sliceTitle}
        <Row alignItems="center" justifyContent="center">
          {sliceContent.subTitle}
        </Row>
        <Buttons>
          <LinkButton
            to={sliceContent.button.to}
            label={sliceContent.button.label}
            size="sm"
          />
          <AWSLink href="https://aws.amazon.com/what-is-cloud-computing">
            <StyledAWSLogo
              src="https://d0.awsstatic.com/logos/powered-by-aws-white.png"
              alt="Powered by AWS Cloud Computing"
              target="_blank"
            />
          </AWSLink>
        </Buttons>
      </Container>
    </Section>
  )
}

export default SignupSlice
