import React, { useEffect, useRef } from 'react'
import { Section } from 'components/Layout'
import styled, { useTheme } from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Arrow from 'images/arrow-forward.svg'
import { DiscoverButton, StyledTag } from 'components/Shared'

const YellowText = styled.span`
  color: ${({ theme }) => theme.palette.secondary.main};
`

const H2 = styled.h2`
  font-family: ${({ theme }) => theme.fontFamilies.main};
  font-weight: ${({ theme }) => theme.fontWeights.titleBold};
  color: ${({ theme }) => theme.palette.primary.main};
  max-width: 520px;
`

const SubTitle = styled.h3`
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  color: ${({ theme }) => theme.palette.primary.gray};
  padding-bottom: 16px;
  ${media.lg`
    padding-bottom: 0px;
  `}
`

const SliceHeader = styled.div`
  ${media.md`
    display: grid;
    grid-template-columns: 1fr 1fr;
  `}
`

const DiscoverLink = styled(Link)`
  margin-top: 24px;
  display: flex;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes.body1};
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-decoration: none;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: calc(50% - 212px);
    width: 0;
    height: 2px;
    background-color: ${({ theme }) => theme.palette.primary.main};
    transition: width 0.3s ease-out;
  }
  &:hover::after {
    width: 424px; /* Expand to full width */
  }
`

const FeaturesGrid = styled.div`
  gap: 32px;
  display: grid;
  margin-top: 40px;
  ${media.md`
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 550px 550px;
  `}
`

const CardContainer = styled.div`
  border: 3px solid;
  border-radius: 24px;
  border-color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.background.gray};
  overflow: hidden;
  padding: 20px 20px 0 20px;
  ${media.lg`
    padding: 40px 40px 0 40px;
  `}
`

const CardTitle = styled.h3`
  font-weight: ${({ theme }) => theme.fontWeights.titleBold};
  font-family: ${({ theme }) => theme.fontFamilies.main};
  font-size: 22px;
  color: ${({ theme }) => theme.palette.primary.main};
`

const CardSubtitle = styled.p`
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  color: ${({ theme }) => theme.palette.primary.gray};
  min-height: 110px;
  ${media.md`
    min-height: 150px;
  `}
  ${media.xl`
    min-height: 90px;
  `}
`

const StyledArrow = styled(Arrow)`
  margin-top: 4px;
`

const StyledVideo = styled.video`
  width: 100%;
  margin: 0 auto;
  display: block;
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.shadows.main};
`;

const FeatureCard = ({ children, title, subtitle, video }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
  
      if (entry.isIntersecting && videoRef.current && videoRef.current.paused) {
        videoRef.current.play();
      } else if (!entry.isIntersecting && videoRef.current) {
        videoRef.current.pause();
      }
    }, {
      threshold: 0.5 
    });
  
    if (videoRef.current) {
      observer.observe(videoRef.current);
  
      videoRef.current.play().catch(() => {
        // Autoplay might be blocked by the browser
        console.warn('Autoplay was prevented');
      }); 
    }
  
    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <CardContainer>
      <CardTitle>{title}</CardTitle>
      <CardSubtitle>{subtitle}</CardSubtitle>
      {video ? (
        <StyledVideo ref={videoRef} autoPlay muted loop> 
          <source src={video.publicURL} type="video/mp4" />
        </StyledVideo>
      ) : (
        children
      )}
    </CardContainer>
  );
};
const FeaturesTeaserWindowsSlice = () => {
  const data = useStaticQuery(graphql`
    query FeatureTeaserABTestingQuery {
      editor: file(relativePath: { eq: "editor-screenshot2.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 65
            width: 1000
            placeholder: NONE
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      illustration: file(relativePath: { eq: "security-illustration.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 65
            width: 1000
            placeholder: NONE
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      templateEditorVideo: file(relativePath: { eq: "template-editor.mp4" }) {
        publicURL
      }
      templateManagementVideo: file(relativePath: { eq: "template-management.mp4" }) {
        publicURL 
      }
      templateImportVideo: file(relativePath: { eq: "template-import.mp4" }) {
        publicURL 
      }
    }
  `)
  const image2 = getImage(data.illustration)
  const theme = useTheme()

  const sliceContent = {
    title: [
      <H2>
        Create high-quality AWS SES templates -{' '}
        <YellowText>quickly</YellowText>
      </H2>,
    ],
    tag: 'FEATURES',
    subtitle:
      "Design high-quality SES email templates and campaigns in minutes, using Semplates' email template editor.",
    button: { label: 'Discover all 40 unique features', to: '/features' },
    cards: [
      {
        title: 'Full control with our Drag & Drop Template editor',
        subtitle:
          'Speed up your process with on-brand re-usable components, AI-generated content, and fully responsive email templates for AWS SES.',
        video: data.templateEditorVideo,
      },
      {
        title: 'Collaborate and Manage your assets all in one place',
        subtitle:
          'No matter if you are managing a handfull of templates or hundreds across multiple SES accounts. Create and attach tags or quickly search, filter and sort your templates and empower your entire team.',
        video: data.templateManagementVideo,
      },
      {
        title: 'Easy import from Amazon SES',
        subtitle:
          'No more dependencies onto your dev team to update email templates on SES: Import your existing email templates from SES and manage all templates in one place with Semplates.',
        video: data.templateImportVideo,
      },
      {
        title: 'Uncompromising Data Security & Compliance',
        subtitle:
          'Ensure the highest level of data protection with Semplates and AWS SES. Our platform is compliant with strict industry regulations, providing peace of mind when handling user data.',
        image: [
          <GatsbyImage image={image2} alt="Illustration for Cybersecurity" />,
        ],
      },
    ],
  }

  return (
    <Section bgStyle="light">
      <StyledTag>{sliceContent.tag}</StyledTag>
      <SliceHeader>
        <div>
          {sliceContent.title}
          <SubTitle>{sliceContent.subtitle}</SubTitle>
        </div>
        <DiscoverButton
          color={theme.palette.primary.main}
          to={sliceContent.button.to}
        >
          {sliceContent.button.label}
        </DiscoverButton>
      </SliceHeader>
      <FeaturesGrid>
        {sliceContent.cards.map(card => (
          <FeatureCard title={card.title} subtitle={card.subtitle} video={card.video}>
            {card.image}
          </FeatureCard>
        ))}
      </FeaturesGrid>
      <DiscoverLink to={sliceContent.button.to}>
        And so much more! Discover all the features here&nbsp;&nbsp;
        <StyledArrow />
      </DiscoverLink>
    </Section>
  )
}

export default FeaturesTeaserWindowsSlice
