import React from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { Col, Row, Section } from 'components/Layout'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import CheckIcon from '../../images/check-icon.svg'
import MinusIcon from '../../images/minus-icon.svg'
import { getOutlink } from '../../utils/analyticsUtils'

const TitleRow = styled(Row)`
  margin: 0 0 16px 0;
`

const TextCol = styled(Col)`
  max-width: 400px;
  height: 100%;
  margin-left: 40px;
  text-align: left;
`

const StyledImage = styled(GatsbyImage)`
  position: relative;
  border-radius: 8px;
  max-width: 450px;
  margin: 32px 0;

  ${media.lg`
    margin: 0 48px 0 16px;
  `}
`

const FeatureText = styled.p`
  font-family: Exo;
  font-weight: ${({ theme }) => theme.fontWeights.medium} !important;
  line-height: 1.3;
  font-size: ${({ theme }) => theme.fontSizes.body1} !important;
  margin: 0;
  padding: 16px 0 32px 0;
`

const Title = styled(FeatureText)`
  font-size: 24px;
  padding: 8px 0;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

const IconBox = styled.div`
  width: 20px;
  height: 20px;
  margin: 0 12px 0 0;
`

const FeatureLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0;
  font-size: ${({ theme }) => theme.fontSizes.body1};
`

const StyledCheckIcon = styled(CheckIcon)`
  width: 18px;
  height: 18px;
`

const StyledMinusIcon = styled(MinusIcon)`
  width: 18px;
  height: 18px;
`

const Container = styled.div`
  display: grid;
  width: 100%;
  justify-content: center;
  ${media.lg`
    display: flex;
    justify-items: center;
  `}
`

const RegisterSlice = ({ sliceTitle }) => {
  const sliceContent = {
    sliceTitle:
      sliceTitle || "Discover the SES template editor. It's free. Forever",
    title: 'Use Semplates for free:',
    text: (
      <span>
        Try Semplates&apos; capabilities within your team&apos;s workflow. The
        discover plan is free of charge, forever.
        <br />
        <br />
        <strong>What&apos;s included in our Discover plan:</strong>
      </span>
    ),

    postFeatureText: (
      <span>
        You can upgrade your plan anytime to enjoy all premium features. We
        offer a <b>60 days money back guarantee</b> - no strings attached.
      </span>
    ),
    includedItems: ['5 Templates', '1 Team member', 'Template starter library'],
    excludedItems: [
      'AI assisted design',
      'Reusable components',
      'Multiple AWS accounts',
      'Advanced Email Personalization',
    ],
    imageAlt: 'Sign up to Semplates.',
    signUpUrl: getOutlink(),
  }

  const data = useStaticQuery(graphql`
    query registerImageQuery {
      signup: file(relativePath: { eq: "ads-landing/signup_form.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 70
            width: 500
            placeholder: NONE
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)
  const image = getImage(data.signup)

  return (
    <Section bgStyle="light">
      <TitleRow>
        <h2>{sliceContent.sliceTitle}</h2>
      </TitleRow>
      <Container>
        <a href={sliceContent.signUpUrl}>
          <StyledImage image={image} alt={sliceContent.imageAlt} />
        </a>
        <TextCol>
          <Title>{sliceContent.title}</Title>
          <FeatureText>{sliceContent.text}</FeatureText>
          {sliceContent.includedItems.map((featureName, index) => (
            <FeatureLine key={index}>
              <IconBox>
                <StyledCheckIcon />
              </IconBox>
              {featureName}
            </FeatureLine>
          ))}
          {sliceContent.excludedItems.map((featureName, index) => (
            <FeatureLine key={index}>
              <IconBox>
                <StyledMinusIcon />
              </IconBox>
              {featureName}
            </FeatureLine>
          ))}
          <FeatureText>{sliceContent.postFeatureText}</FeatureText>
        </TextCol>
      </Container>
    </Section>
  )
}

export default RegisterSlice
