import React, { useState } from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { Section } from 'components/Layout'
import { graphql, useStaticQuery } from 'gatsby'
import { ArticleContent } from '../../utils/templates/styles'
import StyledTag from '../Shared/StyledTag'

const TitleTag = styled(StyledTag)`
  margin: 20px auto;
`

const Title = styled.h2`
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.titleBold};
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: 40px;
`

const QuestionTitle = styled.h3`
  // header menu texts + buttons in general (+ hero subheading is same with weight 400)
  font-family: Exo;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  padding: 0;
  margin: 0;
  ${media.md`
    font-size: 28px;
  `}
}`

const Container = styled.div`
  border: solid 1px;
  border-radius: 8px;
  border-color: ${({ theme }) => theme.palette.border.gray};
  max-width: 900px;
  margin: 8px auto;
  background-color: white;
  padding: 20px 24px;
  font-family:  ${({ theme }) => theme.fontFamilies.secondary};
  & a {
    color: ${({ theme }) => theme.palette.primary.main};
    &:hover {
      font-weight: ${({ theme }) => theme.fontWeights.bold};
    }
  }
  & h3 {
    font-size:  ${({ theme }) => theme.fontSizes.body1};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.palette.primary.main};
    font-family:  ${({ theme }) => theme.fontFamilies.primary};
  }
  & p, li {
    font-size:  ${({ theme }) => theme.fontSizes.body2};
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

const More = styled.div`
  font-size: 20px;
  display: ${({ openAnswer }) => openAnswer ? 'none' : 'block'};
`

const Less = styled.div`
  font-size: 20px;
  display: ${({ openAnswer }) => openAnswer ? 'block' : 'none'};
`

const Answer = styled(ArticleContent)`
  transition: max-height 0.5s ease;
  max-height: ${({ openAnswer }) => openAnswer ? '4000px' : '0px'};
  overflow: hidden;
  & h3 {
    font-size:  ${({ theme }) => theme.fontSizes.body2};
  }

`

const FAQSlice = () => {
  const [openAnswer, setOpenAnswer] = useState(0)
  const data = useStaticQuery(graphql`
    query FAQSliceQuery {
      allContentfulDocumentationPage(
        sort: { fields: sortOrder, order: ASC }
        filter: { documentationCategory: { slug: { eq: "faq" } } }
      ) {
        nodes {
          slug
          id
          documentationCategory {
            slug
          }
          sortOrder
          subTitle
          title
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)

  const questions = data.allContentfulDocumentationPage.nodes
  const sliceContent = {
    title: <>
      You still have questions?
      <br />
      We have answers!
    </>,
    tag: 'FAQ',
  }

  const focusOnElement = (index) => {
    const element = document.getElementById('faq')
    const elementPosition = element.getBoundingClientRect().top
    window.scrollTo({
      top: elementPosition + window.scrollY - 80 + 76 * index,
      behavior: "smooth"
    })
  }

  return (
    <Section>
      <TitleTag>{sliceContent.tag}</TitleTag>
      <Title>{sliceContent.title}</Title>
      {questions.map((question, index) => (
        <Container id="faq">
          <Flex
            id={`question-${index}`}
            onClick={() => {
              if (openAnswer === index + 1) { setOpenAnswer(0) } else {
                setTimeout(() => setOpenAnswer(index + 1), 500)
                setTimeout(() => focusOnElement(index), 0)
              }
            }}>
            <QuestionTitle>{question.title}</QuestionTitle>
            <More
              openAnswer={openAnswer === index + 1}
            >
              <span role="img" aria-label="Read Answer">➕</span>
            </More>
            <Less
              openAnswer={openAnswer === index + 1}
            >
              <span role="img" aria-label="Close Answer">➖</span>
            </Less>
          </Flex>
          <Answer
            dangerouslySetInnerHTML={{ __html: question.content.childMarkdownRemark.html }}
            openAnswer={openAnswer === index + 1}
          />
        </Container>
      )
      )}
    </Section>
  )
}

export default FAQSlice
