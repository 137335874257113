import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import DropdownIcon from 'images/chevron-right.svg'
import { media } from 'styled-bootstrap-grid'
import { Link } from 'gatsby'
import { DocumentationSearch } from '../Documentation'



const StyledSection = styled.div`
  overflow-y: scroll;
  position: fixed;
  z-index: 10;
  height: calc(100vh - 132px);
  background-color: ${({ theme }) => theme.palette.background.gray};
  width: calc(100vw - 32px) ;
  left: 0;
  padding-left: 16px;
  padding-right: 16px;
  top: -100%;
  transition: all ease-in 0.25s;
  ${({ open }) => open && 'top: 132px;'}
  ${media.md`
    padding-left: 0;
    padding-right: 0;
    margin-top: -40px;
    z-index: 20;
    width: 300px !important;
    border-right: solid 1px;
    border-color: ${({ theme }) => theme.palette.border.gray};
    padding-top: 36px;
    padding-bottom: 20px;
    max-height: calc(100vh - 120px);
    position: sticky;
    top: 50px;
  `}
`

const ArticleTitle = styled.div`
  margin: 16px 0;
  font-size: 16px;
`

const Category = styled.p`
  margin-bottom: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 16px;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: 500;
  color: ${({ theme, active }) => active === 1 ? theme.palette.primary.main : theme.palette.primary.gray};
  &: hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

const MobileHeader = styled.div`
  position: fixed;
  top: 72px;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.gray};
  z-index: 11;
  width: calc(100vw - 32px);
  padding: 0 24px;
  left: 0;
  box-shadow: ${({ theme }) => theme.shadows.extralight};
  ${media.md`
    display: none;
  `}
`

const DropdownButton = styled.button`
  background-color: inherit;
  border: none;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  text-align: start;
  width: calc(100% - 72px);
  border-right: solid 1px;
  border-color: ${({ theme }) => theme.palette.border.gray};;
`

const StyledDropdownIcon = styled(DropdownIcon)`
  margin-right: 8px;
  transition: all ease-out 0.1s;
  ${({ open }) => open && 'transform: rotate(90deg);'}
`

const BarContainer = styled.div`
  position: fixed;
  right: 24px;
  width: calc(100vw - 44px);
  ${({ searchBarClosed }) => searchBarClosed && 'width: 40px;'}
  transition: all ease-in 0.25s;
  z-index: 301;
`

const SearchBarButton = styled.div`
  position: absolute;
  width: 48px;
  height: 48px;
  z-index: 301;
`

const DocumentationNavigationSlice = ({ getStartedArticles, templateDesignArticles, faqArticles, currentSlug = "" }) => {
  const [dropDownOpen, setDropDownOpen] = useState(false)
  const [searchBarClosed, setSearchBarClosed] = useState(true)

  const handleClickAnywhere = () => {
    if(!searchBarClosed) {setSearchBarClosed(true)}
  }

  useEffect(() => {
    document.getElementById('closeSearchBar')?.addEventListener('click', handleClickAnywhere);
    return () => {
      document.getElementById('closeSearchBar')?.removeEventListener('click', handleClickAnywhere);
    };
  }, [searchBarClosed, dropDownOpen]);


  return (
    <div>
      <MobileHeader>
        <StyledDropdownIcon
          open={dropDownOpen}
          onClick={() => setDropDownOpen(!dropDownOpen)}
        />
        <DropdownButton
          onClick={() => setDropDownOpen(!dropDownOpen)}
        >
          Documentation menu
        </DropdownButton>
        <BarContainer
          searchBarClosed={searchBarClosed}
        >
          <SearchBarButton
          onClick={() => {
            if(searchBarClosed) setDropDownOpen(false)
            setSearchBarClosed(!searchBarClosed)
          }}
          />
          <DocumentationSearch
            searchBarClosed={searchBarClosed}
          />
        </BarContainer>
      </MobileHeader>
      <StyledSection
        open={dropDownOpen}
      >
        <Category>Getting Started</Category>
        {getStartedArticles.map((article, index) => (
          <StyledLink
            to={`/documentation/${article?.documentationCategory?.slug}/${article.slug}`}
            active={currentSlug === article.slug ? 1 : 0}
          >
            <ArticleTitle key={index + 200}>{article.title}</ArticleTitle>
          </StyledLink>
        ))}
        <Category style={{ marginTop: 40 }}>Template Design</Category>
        {templateDesignArticles.map((article, index) => (
          <StyledLink
            to={`/documentation/${article?.documentationCategory?.slug}/${article.slug}`}
            active={currentSlug === article.slug ? 1 : 0}
          >
            <ArticleTitle key={index + 300}>{article.title}</ArticleTitle>
          </StyledLink>
        ))}

        <Category style={{ marginTop: 40 }}>FAQ</Category>
        {faqArticles.map((article, index) => (
          <StyledLink
            to={`/documentation/${article?.documentationCategory?.slug}/${article.slug}`}
            active={currentSlug === article.slug ? 1 : 0}
          >
            <ArticleTitle key={index + 300}>{article.title}</ArticleTitle>
          </StyledLink>
        ))}
      </StyledSection>
    </div>
  )
}

export default DocumentationNavigationSlice
