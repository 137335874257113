import React from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  ${media.lg`
    margin: 32px 0;
    height: 600px;
  `}
`

const StyledVideoFrame = styled.iframe`
  borderradius: 8;
  width: 100%;
  height: 100%;
`

const EmbeddedVideo = ({ videoSrcURL, videoTitle }) => (
  <Container>
    <StyledVideoFrame
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </Container>
)

export default EmbeddedVideo
