import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { Section } from 'components/Layout'
import StyledTag from 'components/Shared/StyledTag'
import Arrow from 'images/arrow-forward.svg'
import YellowArrow from 'images/arrow-forward-yellow.svg'
import TemplateEditor from 'images/icons/template-editor.svg'
import SesManagement from 'images/icons/ses-management.svg'
import EasyCollaboration from 'images/icons/easy-collaboration.svg'

const Title = styled.h1`
margin-top: 40px;
text-align: center;
line-height: 60px;
@media (max-width: 420px) {
  font-size: 32px;
}
`
const StyledSubtitle = styled.p`
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-size: 20px;
  text-align: center;
  max-width: 750px;
  margin: auto;
  margin-top: 40px;
`

const YellowText = styled.span`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: 900;
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  flex-wrap: wrap; 
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  ${media.lg`
    margin-top: 64px;
    flex-direction: row;
  `}
`

const FeatureCard = styled.div`
  background-color: #fff;
  border-radius: ${({ theme }) => theme.borders.radius};
  width: 400px;
  max-width: 100%;
  text-align: center;
  height: 100%;
  min-height: 144px;
  display: flex;
  flex-direction: row;  
  margin: 16px auto;
  ${media.lg`
    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
  `}
  `

const FeatureCardIconContent = styled.div`
  border-radius: ${({ theme }) => theme.borders.radius};
  width: 80px;
  margin-top: 16px;
`

const FeatureCardContent = styled.div`
  width: 70%;
  min-height: 144px;
  height: 100%;
  text-align: left;
`

const FeatureCardTitle = styled.h3`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 18px;
  margin-top: 14px;
  line-height: 1.2;
`

const FeatureCardText = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: ${({ theme }) => theme.palette.text.primary};
`

const Badge = styled(StyledTag)`
  position: relative;
  top: -94px;
  right: -272px;
  height: 24px;
  width: 88px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.primary.main};
  padding-bottom: 6px;
  font-size: 12px;
`

const DiscoverLink = styled(Link)`
  position: relative;
  top: 0px;
  right: -196px;
  justify-content: center;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 14px;
  text-decoration: none;
  &::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0px;
    width: 0;
    height: 2px;
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
    transition: width 0.25s ease-out;
  }
  &:hover::after {
    width: 106px;
  }
`

const StyledArrow = styled(Arrow)`
  width: 10px;
  height: 10px;
  color: ${({ theme }) => theme.palette.primary.main};
`
const StyledYellowArrow = styled(YellowArrow)`
  margin-top: 4px;
`

const sliceContent = {
  titleClaim: [
    <span><YellowText>Semplates </YellowText> - Your complete solution for</span>,
    <br />,
    <span>creating and managing great <YellowText>AWS SES Email Templates</YellowText></span>,
  ],
  subtitle: [
    <StyledSubtitle>
      Leverage over <YellowText>60+ unique Features</YellowText> for efficient AWS SES Email Template Management.
    </StyledSubtitle>,
  ],

  editorDisclaimerText: "Learn below how Semplates can work for you or",
  editorDisclaimerLink: "/editor/?template=Semplates%20Demo%20Template",
  editorDisclaimerLinkText: "try the editor for yourself",
  imageAlt:
    'The Semplates template editor makes it easy to design personalized, responsive, and branded transactional email templates via drag and drop - no coding skills required.',
  features: [
    {
      title: 'Template Editor',
      text: 'Intuitive Drag-n-drop and HTML email template editor and more',
      link: '#editor',
      iconName: 'editorIcon',
      imageAlt: 'Template Editor Icon',
      badgeText: '17+ features',
      Icon: TemplateEditor,
    },
    {
      title: 'SES Management',
      text: 'Seamless integration with AWS SES and more',
      link: '#management',
      iconName: 'sesManagementIcon',
      imageAlt: 'SES Management Icon',
      badgeText: '8+ features',
      Icon: SesManagement,
    },
    {
      title: 'Easy Collaboration',
      text: 'Invite your team to collaborate on your templates and more',
      link: '#collaboration',
      iconName: 'collaborationIcon',
      imageAlt: 'Easy Collaboration Icon',
      badgeText: '6+ features',
      Icon: EasyCollaboration,
    },
  ]
}
const HeroContent = () => (
      <>
        <Title>
          {sliceContent.titleClaim}
        </Title>
        {sliceContent.subtitle}
        <CardContainer>
          {sliceContent.features.map(( { badgeText, title, text, link, Icon}, index) => (
            <FeatureCard key={index}>
              <FeatureCardIconContent>
              <Icon/>
              {badgeText && <Badge>{badgeText}</Badge>} 
              </FeatureCardIconContent>
              <FeatureCardContent>
                <FeatureCardTitle>{title}</FeatureCardTitle>
                <FeatureCardText>{text}</FeatureCardText>
                <>
                  <DiscoverLink href={link}>Discover more&nbsp;&nbsp;<StyledArrow /></DiscoverLink>
                </>
              </FeatureCardContent>
            </FeatureCard>
          ))}
        </CardContainer>
        <StyledSubtitle>
        {sliceContent.editorDisclaimerText}{' '}
          <Link to={sliceContent.editorDisclaimerLink}>
            <YellowText>
                    {sliceContent.editorDisclaimerLinkText}&nbsp;&nbsp;<StyledYellowArrow />
            </YellowText>
          </Link>
        </StyledSubtitle>
      </>
  );

const FeaturesSliceHero = () => (
    <Section bgStyle="dark">
    <HeroContent />
    </Section>
  )

export default FeaturesSliceHero
